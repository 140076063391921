<template>
  <v-dialog v-model="dialog" max-width="920px" scrollable @click:outside="clear">
    <v-card>
      <v-card-item class="pl-5 py-2 pr-2">
        <v-card-title class="d-flex align-center">
          <span>{{ $t('CaseList.editDemoData') }}</span>
          <v-spacer />
          <v-btn v-blur icon="mdi-close" density="comfortable" variant="text" @click="clear" />
        </v-card-title>
      </v-card-item>
      <v-card-text class="pa-0" style="height: 380px">
        <v-form ref="form" v-model="valid">
          <v-tabs v-model="tab" bg-color="primary" class="mb-4" theme="dark">
            <v-tab>
              {{ $t('CaseList.EditDemoInfo.personalDetails') }}
            </v-tab>
            <v-tab>
              {{ $t('Common.occupation') }}
            </v-tab>
            <v-tab>
              {{ $t('CaseList.EditDemoInfo.damage') }}
            </v-tab>
          </v-tabs>

          <v-window v-model="tab" class="px-4">
            <v-window-item>
              <div class="d-flex">
                <v-select v-model="personal.gender" class="pa-2" :items="genders" :label="$t('Common.gender')" />
                <v-text-field v-model="personal.yearOfBirth" class="pa-2" :rules="personYearOfBirthRules" :label="$t('Common.yearOfBirth')" />
              </div>
            </v-window-item>
            <v-window-item>
              <div class="d-flex">
                <div class="flex-grow-1">
                  <v-text-field v-model="personal.jobTitle" class="pa-2" :label="$t('Common.occupation')" />
                  <v-select v-model="personal.jobPosition" class="pa-2" :items="jobPositions" :label="$t('Common.professionalPosition')" />
                </div>
                <div class="flex-grow-1">
                  <v-select v-model="personal.jobCompetenceLevel" class="pa-2" :items="jobCompetenceLevels" :label="$t('Common.levelOfCompetence')" />
                  <v-select v-model="personal.jobPhysicalLoad" class="pa-2" :items="jobPhysicalLoads" :label="$t('Common.physicalLoad')" />
                </div>
              </div>
            </v-window-item>
            <v-window-item>
              <div class="d-flex">
                <div class="flex-grow-1">
                  <v-text-field v-model="damage.earnings" class="pa-2" :label="$t('CaseList.EditDemoInfo.lossOfEarnings')" />
                  <v-text-field v-model="damage.home" class="pa-2" :label="$t('CaseList.EditDemoInfo.homeDamage')" />
                  <v-text-field v-model="damage.care" class="pa-2" :label="$t('CaseList.EditDemoInfo.childCareDamage')" />
                  <v-text-field v-model="damage.costs" class="pa-2" :label="$t('CaseList.EditDemoInfo.costs')" />
                </div>
                <div class="flex-grow-1">
                  <v-text-field v-model="damage.other" class="pa-2" :label="$t('CaseList.EditDemoInfo.otherDamage')" />
                  <v-text-field v-model="damage.property" class="pa-2" :label="$t('CaseList.EditDemoInfo.propertyDamage')" />
                  <v-text-field v-model="damage.compensation" class="pa-2" :label="$t('CaseList.EditDemoInfo.compensation')" />
                  <v-text-field v-model="damage.lawyerCost" class="pa-2" :label="$t('CaseList.EditDemoInfo.lawyerCost')" />
                </div>
              </div>
            </v-window-item>
          </v-window>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end">
        <v-btn color="primary" variant="text" data-testid="btn_cancel" @click="clear">
          {{ $t('Common.cancel') }}
        </v-btn>
        <v-btn color="primary" variant="flat" data-testid="btn_saveUpdated" @click="saveUpdated">
          {{ $t('Common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { VForm } from 'vuetify/lib/components/index.mjs';

import { LegalCase } from '@/case-list/services/legalcase.service';
import { legalCaseAPIClient } from '@/common/clients/legalcase.api.client';
import { GENDERS, JOB_COMPETENCE_LEVELS, JOB_PHYSICAL_LOADS, JOB_POSITIONS } from '@/similar-cases/services/similar.cases.helper';

interface Personal {
  gender: string;
  yearOfBirth: string;
  jobTitle: string;
  jobPosition: string;
  jobCompetenceLevel: string;
  jobPhysicalLoad: string;
}

interface Damage {
  earnings: string;
  home: string;
  care: string;
  costs: string;
  other: string;
  property: string;
  compensation: string;
  lawyerCost: string;
}

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    inputCase: {
      type: Object as PropType<LegalCase>,
      required: true,
    },
  },

  emits: ['close'],

  data() {
    return {
      personal: {
        gender: '',
        yearOfBirth: '',
        jobTitle: '',
        jobPosition: '',
        jobCompetenceLevel: '',
        jobPhysicalLoad: '',
      } as Personal,
      valid: false,
      personYearOfBirthRules: [
        (v: string) => v.length === 0 || !!parseInt(v, 10) || this.$t('CaseList.EditDemoInfo.enterInTheFormatOf1990'),
        // pattern to match yyyy-mm-dd format from extracted birthrates
        (v: string) =>
          v.length === 0 ||
          !!v.match(/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/g) ||
          !!v.match(/^[1-2]\d{3}$/g) ||
          this.$t('CaseList.EditDemoInfo.enterInTheFormatOf1990'),
      ],

      damage: {
        earnings: '',
        home: '',
        care: '',
        costs: '',
        other: '',
        property: '',
        compensation: '',
        lawyerCost: '',
      } as Damage,
      tab: null,
    };
  },

  computed: {
    inputCaseId() {
      return this.inputCase.id || '';
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    genders() {
      const result = [];
      for (const [key, value] of Object.entries(GENDERS())) {
        result.push({
          value: key,
          title: value,
        });
      }
      return result;
    },
    jobPositions() {
      const result = [];
      for (const [key, value] of Object.entries(JOB_POSITIONS())) {
        result.push({
          value: key,
          title: value,
        });
      }
      return result;
    },
    jobCompetenceLevels() {
      const result = [];
      for (const [key, value] of Object.entries(JOB_COMPETENCE_LEVELS())) {
        result.push({
          value: key,
          title: value,
        });
      }
      return result;
    },
    jobPhysicalLoads() {
      const result = [];
      for (const [key, value] of Object.entries(JOB_PHYSICAL_LOADS())) {
        result.push({
          value: key,
          title: value,
        });
      }
      return result;
    },
  },

  watch: {
    inputCaseId: {
      handler(legalCaseId) {
        legalCaseAPIClient.fetchMetadata(legalCaseId, 'INTERNAL_PERSON').then((response) => {
          this.personal = {
            gender: response.gender || '',
            yearOfBirth: response.yearOfBirth ? `${response.yearOfBirth}` : '',
            jobTitle: response.jobTitle || '',
            jobPosition: response.jobPosition || '',
            jobCompetenceLevel: response.jobCompetenceLevel || '',
            jobPhysicalLoad: response.jobPhysicalLoad || '',
          };
        });
        legalCaseAPIClient.fetchMetadata(legalCaseId, 'INTERNAL_DAMAGE').then((response) => {
          this.damage = {
            earnings: response.earnings || 0,
            home: response.home || 0,
            care: response.care || 0,
            costs: response.costs || 0,
            other: response.other || 0,
            property: response.property || 0,
            compensation: response.compensation || 0,
            lawyerCost: response.lawyerCost || 0,
          };
        });
      },
      immediate: true,
    },
  },

  beforeUnmount() {
    this.tab = null;
    this.clear();
  },

  methods: {
    clear() {
      if (this.$refs.form) {
        (this.$refs.form as VForm).resetValidation();
      }
      this.dialog = false;
    },
    async saveUpdated() {
      // NOTE(mba): DEMO only. to be removed, once demo features use real PII data
      const form = this.$refs.form as VForm | undefined;
      const validationResult = await form?.validate();
      if (!validationResult?.valid) {
        return;
      }
      // persist case changes

      // persist metadata changes
      const personal: Record<string, string | number> = {};
      Object.keys(this.personal).forEach((property) => (personal[property] = this.personal[property as keyof Personal].trim()));
      // yearOfBirth is a number
      if (personal.yearOfBirth) {
        personal.yearOfBirth = parseInt(personal.yearOfBirth as string, 10);
      }

      legalCaseAPIClient.updateMetadata(this.inputCaseId, 'INTERNAL_PERSON', JSON.stringify(personal));

      // persist metadata changes
      const damage: Record<string, string | number> = {};
      Object.keys(this.damage).forEach((property) => {
        damage[property] = parseInt(this.damage[property as keyof Damage], 10) || 0;
      });
      legalCaseAPIClient.updateMetadata(this.inputCaseId, 'INTERNAL_DAMAGE', JSON.stringify(damage));
      this.clear();
    },
  },
});
</script>
