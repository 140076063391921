// import default locales
// https://github.com/iamkun/dayjs/tree/dev/src/locale
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/fr-ch';
import 'dayjs/locale/it-ch';

import dayjs from 'dayjs';
// import enGB from 'dayjs/locale/en-gb';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

// extend dayjs
dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(updateLocale);
dayjs.extend(minMax);
