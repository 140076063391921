import axios from 'axios';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';

export const ExtractorTask = {
  MEDICAL_ICD10: 'MEDICAL_ICD10',
  REF: 'REF',
  TITLE: 'TITLE',
  DOCTYPE: 'DOCTYPE',
  SUMMARY: 'SUMMARY',
  ISSUE_DATE: 'ISSUE_DATE',
  AUTHOR: 'AUTHOR',
  AUTHOR_INSTITUTION: 'AUTHOR_INSTITUTION',
  AUTHOR_SPECIALITY: 'AUTHOR_SPECIALITY',
  AUTHOR_POSITION: 'AUTHOR_POSITION',
  RECIPIENT: 'RECIPIENT',
  RECIPIENT_INSTITUTION: 'RECIPIENT_INSTITUTION',
  WORK_INABILITY: 'WORK_INABILITY',
  QUALITY: 'QUALITY',
  LANGUAGE: 'LANGUAGE',
  FOLDER: 'FOLDER',
  TIMELINES: 'TIMELINES',
};

export const pipelineOrchestratorService = {
  /**
   * triggers a supervisor run
   * @returns undefined
   */
  async runSupervisor() {
    return axios
      .get(config.API.PIPELINE.RUN_SUPERVISOR)
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseList.supervisorStartError'), e);
      });
  },

  async requestTask(sourceFileIds, status, extractors = []) {
    const request = {
      sourceFileIds: Array.isArray(sourceFileIds) ? sourceFileIds : [sourceFileIds],
      requestedStatus: status,
      forcedExtractors: extractors,
    };

    return axios.post(`${config.API.PIPELINE.REQUEST_TASK}`, request).catch((e) => {
      handleError($t('CaseList.taskUpdateError'), e);
    });
  },

  async requestTaskRestart(legalCaseId = '') {
    return axios
      .post(`${config.API.PIPELINE.REQUEST_RESTART_TASK}${legalCaseId ? `?legalCaseId=${legalCaseId}` : ''}`)
      .catch((e) => handleError($t('CaseList.taskUpdateError'), e));
  },

  async cancelTask(sourceFileId) {
    return axios.post(`${config.API.PIPELINE.CANCEL_TASK}/${sourceFileId}`).catch((e) => {
      handleError($t('CaseList.taskCancelError'), e);
    });
  },

  async reviewTaskFinished(legalCaseId) {
    return axios.post(`${config.API.PIPELINE.REVIEW_TASK_FINISHED}/${legalCaseId}`).catch((e) => {
      handleError($t('CaseList.taskUpdateError'), e);
    });
  },

  async requestTasksOnTenant(newStatus, oldStatus) {
    return axios.post(`${config.API.PIPELINE.REQUEST_TENANT_TASKS}/${oldStatus}/${newStatus}`).catch((e) => {
      handleError($t('CaseList.taskStartError'), e);
    });
  },

  /**
   * fetch audit logs for a specific source field id
   * @param {*} sourceFileId
   * @returns Array with found logs
   */
  async getAuditLog(sourceFileId) {
    return axios
      .get(config.API.PIPELINE.AUDIT.replace('{sourceFileId}', sourceFileId))
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseList.auditLogLoadError'), e);
      });
  },
};
