import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import $a from '@/common/services/analytics/analytics';
import { TicketEventName } from '@/common/services/entity.service';
import fileService from '@/common/services/file.service';
import { API } from '@/common/types/api.types';
import { ListItemResponse, TicketEventMetadata } from '@/common/types/api-types/ticket.api.types';
import { UUID } from '@/common/types/common.types';

export const ticketClient = {
  async loadWorkspaceTickets(): Promise<API.Ticket.ListItemResponse[]> {
    try {
      const response = await axios.get(config.API.LEGALCASE_TICKET_ENDPOINT.ALL_TICKETS);

      if (Array.isArray(response.data)) {
        return response.data;
      }
    } catch (e) {
      handleError($t('Tickets.ticketsLoadError'), e);
    }

    return [];
  },

  async loadCaseTickets(caseId: UUID): Promise<API.Ticket.Response[]> {
    try {
      const response = await axios.get(config.API.LEGALCASE_TICKET_ENDPOINT.CASE_TICKETS.replace('{legalCaseId}', caseId));

      if (Array.isArray(response.data)) {
        return response.data;
      }
    } catch (e) {
      handleError($t('Tickets.ticketsLoadError'), e);
    }

    return [];
  },

  async loadTicket(caseId: UUID, ticketId: UUID): Promise<API.Ticket.Response | null> {
    try {
      const { data } = await axios.get(config.API.LEGALCASE_TICKET_ENDPOINT.TICKET.replace('{legalCaseId}', caseId).replace('{ticketId}', ticketId));
      return data;
    } catch (e) {
      handleError($t('Tickets.ticketLoadError'), e);
    }

    return null;
  },

  async createTicket(ticket: API.Ticket.CreateRequest, caseId: UUID): Promise<API.Ticket.Response | null> {
    const uri = config.API.LEGALCASE_TICKET_ENDPOINT.CASE_TICKETS.replace('{legalCaseId}', caseId);

    try {
      const response = await axios.post(uri, ticket);
      $a.l($a.e.COLLABORATION_TICKET_CREATE);
      if (response.data.coordinator) {
        $a.l($a.e.COLLABORATION_TICKET_COORDINATOR_ASSIGN);
      } else {
        $a.l($a.e.COLLABORATION_TICKET_RECPIENT_ASSIGN);
      }
      return response.data;
    } catch (e) {
      handleError($t('Tickets.ticketSaveError'), e);
      return null;
    }
  },

  async emitEvent<E extends TicketEventName>(caseId: UUID, ticketId: UUID, event: E, userId: string, metadata: TicketEventMetadata[E]) {
    const uri = config.API.LEGALCASE_TICKET_ENDPOINT.EVENTS.replace('{legalCaseId}', caseId).replace('{ticketId}', ticketId);
    try {
      const response = await axios.post(uri, {
        userId,
        metadata,
        eventName: event,
      });
      return response.data;
    } catch (e) {
      handleError($t('Tickets.ticketSaveError'), e);
    }
    return null;
  },

  async updateMessage(caseId: UUID, ticketId: UUID, newTicketEvent: API.Ticket.TicketEvent) {
    const uri = config.API.LEGALCASE_TICKET_ENDPOINT.EVENT.replace('{legalCaseId}', caseId)
      .replace('{ticketId}', ticketId)
      .replace('{eventId}', newTicketEvent.id);
    try {
      const response = await axios.patch(uri, newTicketEvent);
      return response.data;
    } catch (e) {
      handleError($t('Tickets.messageUpdateError'), e);
    }
    return null;
  },

  async deleteMessage(caseId: UUID, ticketId: UUID, eventId: UUID) {
    const uri = config.API.LEGALCASE_TICKET_ENDPOINT.EVENT.replace('{legalCaseId}', caseId)
      .replace('{ticketId}', ticketId)
      .replace('{eventId}', eventId);
    try {
      const response = await axios.delete(uri);
      return response.data;
    } catch (e) {
      handleError($t('Tickets.messageDeleteError'), e);
    }
    return null;
  },

  getExtension(filename: string) {
    const dotPos = filename.lastIndexOf('.');
    if (dotPos !== -1) {
      return filename.substring(dotPos + 1);
    }
    return '';
  },

  requestAccess(ticket: ListItemResponse) {
    const uri = config.API.LEGALCASE_TICKET_ENDPOINT.REQUEST_ACCESS.replace('{legalCaseId}', ticket.legalCaseId).replace('{ticketId}', ticket.id);
    return axios.post(uri);
  },

  async addAttachment(caseId: UUID, ticketId: UUID, fieldId: 'question' | 'answer' | UUID, file: File): Promise<API.Ticket.Attachment> {
    try {
      const extension = this.getExtension(file.name);
      const attachmentId = uuidv4();
      const presignedUploadURIResponse = await fileService.upload(file);

      const uri = config.API.LEGALCASE_TICKET_ENDPOINT.ATTACHMENT.replace('{legalCaseId}', caseId)
        .replace('{ticketId}', ticketId)
        .replace('{fieldId}', fieldId)
        .replace('/{attachmentId}', '');

      const response = await axios.post(uri, {
        id: attachmentId,
        contentType: file.type,
        description: file.name,
        legalCaseId: caseId,
        tempFilename: presignedUploadURIResponse.tempFilename,
        extension,
      });

      $a.l($a.e.COLLABORATION_TICKET_ATTACHMENT_ADD);
      return response.data;
    } catch (e) {
      handleError($t('Tickets.fileUploadError'), e);
      throw e;
    }
  },

  async deleteAttachment(caseId: UUID, ticketId: UUID, attachment: API.Ticket.Attachment) {
    const uri = config.API.LEGALCASE_TICKET_ENDPOINT.ATTACHMENT.replace('{legalCaseId}', caseId)
      .replace('{ticketId}', ticketId)
      .replace('{fieldId}', attachment.ticketFieldReference)
      .replace('{attachmentId}', attachment.id);
    try {
      await axios.delete(uri);
      $a.l($a.e.COLLABORATION_TICKET_ATTACHMENT_DELETE);
      appService.info($t('Tickets.fileDeleted'));
      return true;
    } catch (e) {
      handleError($t('Tickets.fileDeleteError'), e);
    }
    return false;
  },

  async deleteTicket(ticket: API.Ticket.Response) {
    const uri = config.API.LEGALCASE_TICKET_ENDPOINT.TICKET.replace('{legalCaseId}', ticket.legalCaseId).replace('{ticketId}', ticket.id);
    try {
      await axios.delete(uri);
      $a.l($a.e.COLLABORATION_TICKET_DELETE);
      appService.info($t('Tickets.ticketDeleted'));
      return true;
    } catch (e) {
      handleError($t('Tickets.ticketDeleteError'), e);
    }
    return false;
  },
};
