import axios from 'axios';
import dayjs from 'dayjs';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import documentService from '@/case-detail/subviews/document/services/document.service';
import documentFilterService from '@/case-detail/subviews/documents-list/services/filter/document.filter.service';
import EXPORT_CONFIG_TEMPLATES from '@/case-detail/subviews/export/EXPORT_CONFIG_TEMPLATES';
import labelService from '@/case-detail/subviews/labels/services/label.service';
import { getMainLabel } from '@/case-detail/subviews/labels/services/labels.utils';
import $a from '@/common/services/analytics/analytics';
import { authService } from '@/common/services/auth/auth.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import { formatToLocale } from '@/common/services/date.utils';
import entityService from '@/common/services/entity.service';
import fileService from '@/common/services/file.service';

export const EntryType = {
  DOCUMENT: 'DOCUMENT',
  HEADING: 'HEADING',
};

export const ExportView = {
  ExportList: 1,
  TemplateSelection: 2,
  DocumentSelection: 3,
  Configuration: 4,
  Share: 5,
};

export const ExportConfigTemplates = EXPORT_CONFIG_TEMPLATES;

export const SortingModes = {
  MANUAL: {
    key: 'MANUAL',
    titleKey: 'CaseDetail.Export.SortModes.MANUAL',
    icon: 'mdi-hand-back-right-outline',
  },
  ISSUE_DATE_ASC: {
    key: 'ISSUE_DATE_ASC',
    titleKey: 'CaseDetail.Export.SortModes.ISSUE_DATE_ASC',
    icon: 'mdi-sort-calendar-ascending',
  },
  ISSUE_DATE_DESC: {
    key: 'ISSUE_DATE_DESC',
    titleKey: 'CaseDetail.Export.SortModes.ISSUE_DATE_DESC',
    icon: 'mdi-sort-calendar-descending',
  },
  RECEIPT_DATE_ASC: {
    key: 'RECEIPT_DATE_ASC',
    titleKey: 'CaseDetail.Export.SortModes.RECEIPT_DATE_ASC',
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" fill="none"><path fill="currentColor" d="M16 17h3V3h2v14h3l-4 4-4-4ZM2.4 9.463l5.6 4.39 5.6-4.39L8 5.073l-5.6 4.39Zm12.6 0v8.78c0 .467-.148.913-.41 1.243-.263.329-.619.514-.99.514H2.4c-.371 0-.727-.185-.99-.514A2.005 2.005 0 0 1 1 18.244V9.463c0-.641.273-1.194.679-1.502L8 3l6.321 4.961c.406.308.679.86.679 1.502Z"/></svg>',
  },
  RECEIPT_DATE_DESC: {
    key: 'RECEIPT_DATE_DESC',
    titleKey: 'CaseDetail.Export.SortModes.RECEIPT_DATE_DESC',
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" fill="none"><path fill="currentColor" d="M16 7h3v14h2V7h3l-4-4-4 4ZM2.4 9.463l5.6 4.39 5.6-4.39L8 5.073l-5.6 4.39Zm12.6 0v8.78c0 .467-.148.913-.41 1.243-.263.329-.619.514-.99.514H2.4c-.371 0-.727-.185-.99-.514A2.005 2.005 0 0 1 1 18.244V9.463c0-.641.273-1.194.679-1.502L8 3l6.321 4.961c.406.308.679.86.679 1.502Z"/></svg>',
  },
  PAGINATION_NO_DESC: {
    key: 'PAGINATION_NO_DESC',
    titleKey: 'CaseDetail.Export.SortModes.PAGINATION_NO_DESC',
    iconSvg:
      '<svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.66397 18L3.23887 14H0L0.283401 12H3.52227L4.38057 6H1.1417L1.4251 4H4.66397L5.23887 0H6.8583L6.2834 4H11.1417L11.7166 0H13.336L12.7611 4H16L15.7166 6H12.4777L11.6194 12H14.8583L14.5749 14H11.336L10.7611 18H9.1417L9.7166 14H4.8583L4.2834 18H2.66397ZM6 6L5.1417 12H10L10.8583 6H6Z" fill="black"/> <path d="M22 14H25L21 18L17 14H20V0H22" fill="black"/> </svg>',
  },
  PAGINATION_NO_ASC: {
    key: 'PAGINATION_NO_ASC',
    titleKey: 'CaseDetail.Export.SortModes.PAGINATION_NO_ASC',
    iconSvg:
      '<svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M17.0002 4H20.0002V18H22.0002V4H25.0002L21.0002 0L17.0002 4Z" fill="black"/> <path d="M2.66397 18L3.23887 14H0L0.283401 12H3.52227L4.38057 6H1.1417L1.4251 4H4.66397L5.23887 0H6.8583L6.2834 4H11.1417L11.7166 0H13.336L12.7611 4H16L15.7166 6H12.4777L11.6194 12H14.8583L14.5749 14H11.336L10.7611 18H9.1417L9.7166 14H4.8583L4.2834 18H2.66397ZM6 6L5.1417 12H10L10.8583 6H6Z" fill="black"/> </svg>',
  },
};

export const TocTitles = () => [
  $t('CaseDetail.Export.TocTitles.index'),
  $t('CaseDetail.Export.TocTitles.edition'),
  $t('CaseDetail.Export.TocTitles.toc'),
  $t('CaseDetail.Export.TocTitles.appendices'),
];

class ExportService {
  constructor() {
    this.state = reactive({
      view: ExportView.ExportList,
      error: null,
      isLoading: false,
      isFirstPreview: true,
      allowSave: false,
      showDropOverlay: false,

      // docs
      documentExportsList: null,
      documentExportHierarchyMap: null,
      selectedDocumentExport: null,

      // filter
      labelFilterOptions: [],
      labelFilter: null,
    });
    this.documentExportsMap = null;
    this.currentCase = null;
  }

  async init(curCase) {
    this.currentCase = curCase;
    await this.fetchAll();
  }

  /** GETTERS / SETTERS */

  isLoading() {
    return this.state.isLoading;
  }

  setLoading(isLoading) {
    this.state.isLoading = isLoading;
  }

  getCurrentlySelectedDocumentIds() {
    const entries = this.state.selectedDocumentExport?.documentEntries ?? [];
    return entries.filter((e) => e.type === EntryType.DOCUMENT).map((e) => e.documentId);
  }

  getPreviouslyExportedDocumentIds() {
    return this.state.selectedDocumentExport?.prevDocumentIds ?? [];
  }

  /** CRUD */

  async create(recipient) {
    const response = await axios.put(config.API.EXPORTS_ENDPOINT.CREATE.replace('{legalCaseId}', this.currentCase.id), {
      recipient,
    });

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      ...response.data,
    };

    this.updateLabelFilterOptions();

    this.overrideDefaultConfig();

    this.state.documentExportsList.push(this.state.selectedDocumentExport);
    this.updateMaps();

    // NOTE(ndv): hacky solution for now, since we need for change management for baloise
    if (authService.state.data.tenant.canonicalName.includes('baloise')) {
      this.state.view = ExportView.DocumentSelection;
    } else {
      this.state.view = ExportView.TemplateSelection;
    }
    $a.l($a.e.EXPORT_ADD);
  }

  async createUpdate(previousDocumentExport) {
    const response = await axios.put(
      config.API.EXPORTS_ENDPOINT.CREATE_UPDATE.replace('{legalCaseId}', this.currentCase.id).replace('{exportId}', previousDocumentExport.id),
      {
        recipient: previousDocumentExport.recipient,
      },
    );
    const prevDocIds = previousDocumentExport.documentEntries.filter((e) => e.type === EntryType.DOCUMENT).map((d) => d.documentId);
    prevDocIds.push(...previousDocumentExport.prevDocumentIds);

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      prevDocumentIds: prevDocIds,
      ...response.data,
    };
    this.updateLabelFilterOptions();
    // NOTE: sets filename
    this.overrideDefaultConfig();

    this.state.documentExportsList.push(this.state.selectedDocumentExport);
    this.updateMaps();

    this.state.view = ExportView.DocumentSelection;
    $a.l($a.e.EXPORT_ADD_UPDATE);
  }

  async createCopy(originalExportId, recipient) {
    this.state.isLoading = true;
    const response = await axios.post(
      config.API.EXPORTS_ENDPOINT.COPY.replace('{legalCaseId}', this.currentCase.id).replace('{exportId}', originalExportId),
      {
        recipient,
      },
    );

    this.state.documentExportsList.push(response.data);
    this.updateMaps();
    this.state.isLoading = false;
    $a.l($a.e.EXPORT_ADD_COPY);
  }

  async saveDocuments() {
    this.sort();

    const response = await axios.patch(
      config.API.EXPORTS_ENDPOINT.DOCUMENTS.replace('{legalCaseId}', this.currentCase.id).replace('{exportId}', this.state.selectedDocumentExport.id),
      {
        documentEntries: this.state.selectedDocumentExport.documentEntries,
        sorting: this.state.selectedDocumentExport.configuration.sorting,
        groupByLabels: this.state.selectedDocumentExport.configuration.groupByLabels,
      },
    );

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      ...response.data,
    };

    this.updateLabelFilterOptions();
  }

  async saveConfig() {
    const requestData = {
      ...this.state.selectedDocumentExport.configuration,
    };

    let response;
    try {
      response = await axios.patch(
        config.API.EXPORTS_ENDPOINT.CONFIG.replace('{legalCaseId}', this.currentCase.id).replace('{exportId}', this.state.selectedDocumentExport.id),
        requestData,
      );
    } catch (e) {
      handleError($t('CaseDetail.Export.exportCannotBeSavedErrorMessage'), e);
    }

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      ...response.data,
    };
    this.updateLabelFilterOptions();
  }

  async update(exportId, payload) {
    await axios.patch(config.API.EXPORTS_ENDPOINT.UPDATE.replace('{legalCaseId}', this.currentCase.id).replace('{exportId}', exportId), {
      ...payload,
    });

    let documentExport = this.state.documentExportsList.find((e) => e.id === exportId);
    if (!documentExport) return;

    documentExport = {
      ...documentExport,
      ...payload,
    };
    this.updateLocalExport(documentExport);
  }

  async delete(documentExport) {
    await axios.delete(`${config.API.EXPORTS_ENDPOINT.BASE.replace('{legalCaseId}', this.currentCase.id)}/${documentExport.id}`);

    this.state.selectedDocumentExport = null;
    this.state.documentExportsList = this.state.documentExportsList.filter((de) => de.id !== documentExport.id);
    this.updateMaps();

    $a.l($a.e.EXPORT_DELETE);
  }

  async publish() {
    this.state.isLoading = true;

    // verify documents
    const validDocumentEntries = this.state.selectedDocumentExport.documentEntries.filter((entry) => {
      if (entry.type !== EntryType.DOCUMENT) return true;

      const document = documentService.getDocumentsCache().get(entry.documentId);
      return !!document && document.status === 'ACTIVE';
    });

    if (validDocumentEntries.length !== this.state.selectedDocumentExport.documentEntries.length) {
      appService.info($t('CaseDetail.Export.someDocumentsInvalidInfoMessage'));
      this.state.selectedDocumentExport.documentEntries = [...validDocumentEntries];
      await this.saveDocuments();
      this.state.view = ExportView.DocumentSelection;
      return;
    }

    await this.saveConfig();

    let response;
    try {
      response = await axios.post(
        config.API.EXPORTS_ENDPOINT.PUBLISH.replace('{legalCaseId}', this.currentCase.id).replace('{exportId}', this.state.selectedDocumentExport.id),
      );
    } catch (e) {
      handleError($t('CaseDetail.Export.exportPublishErrorMessage'), e);
      return;
    }
    documentService.load(this.currentCase.id);

    this.state.selectedDocumentExport = {
      ...this.state.selectedDocumentExport,
      ...response.data,
    };
    this.updateLocalExport(this.state.selectedDocumentExport);

    this.state.isLoading = false;
    this.state.view = ExportView.Share;

    $a.l($a.e.EXPORT_PUBLISH);
  }

  async fetchHistory(exportId) {
    return axios
      .get(config.API.EXPORTS_ENDPOINT.HISTORY.replace('{legalCaseId}', this.currentCase.id).replace('{exportId}', exportId))
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseDetail.Export.historyLoadErrorMessage'), e);
      });
  }

  async fetchAll() {
    try {
      const documentExportResponse = await axios.get(config.API.EXPORTS_ENDPOINT.BASE.replace('{legalCaseId}', this.currentCase.id));
      this.state.documentExportsList = documentExportResponse.data;
      this.updateMaps();

      for (const documentExport of this.state.documentExportsList) {
        const prevDocumentIds = [];
        let { previousExportId } = documentExport;
        while (previousExportId) {
          const previousDocumentExport = this.documentExportsMap.get(previousExportId);
          const previousDocumentExportDocumentIds = previousDocumentExport.documentEntries
            .filter((e) => e.type === EntryType.DOCUMENT)
            .map((d) => d.documentId);
          prevDocumentIds.unshift(...previousDocumentExportDocumentIds);
          previousExportId = previousDocumentExport.previousExportId;
        }
        documentExport.prevDocumentIds = prevDocumentIds;
      }
    } catch (e) {
      handleError($t('CaseDetail.Export.exportsLoadErrorMessage'), e);
    }
  }

  async overrideDefaultConfig() {
    if (!this.state.selectedDocumentExport.configuration.filename) {
      const [filenames] = this.getFilenamesForSelect();
      this.state.selectedDocumentExport.configuration.filename = filenames.value;
    }
    await this.saveConfig();
  }

  async applyConfigTemplate(templateObject) {
    // apply template
    this.state.selectedDocumentExport.configuration = {
      ...this.state.selectedDocumentExport.configuration,
      ...templateObject,
    };

    await this.overrideDefaultConfig();

    // pre-fill export
    if (this.state.selectedDocumentExport.configuration.predefinedLabelSelection.length === 0) return;

    this.addDocumentsByPredefinedLabelSelection();
  }

  /** PDF */

  async downloadPdf(documentExport) {
    appService.info($t('Common.File.preparingDownload'));
    const filename = this.parseFilenameTemplate(documentExport.configuration.filename, documentExport);
    fileService.download(documentExport.fileUri, filename);
  }

  /** DOCUMENTS */

  async addDocuments(documentIds) {
    // NOTE(ndv): the idea is to not impact the loading when it's already managed outside this method, e.g. from methods that call this method inside them
    let resetLoading = false;
    if (!this.isLoading()) {
      this.setLoading(true);
      resetLoading = true;
    }

    const documents = documentIds.map((documentId) => documentService.getDocumentsCache().get(documentId));
    documents.sort((a, b) => this.sortByLabel(a, b, 'id'));

    let added = 0;
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      if (
        this.state.selectedDocumentExport.documentEntries.find((e) => e.documentId === document.id) ||
        this.state.selectedDocumentExport.prevDocumentIds?.includes(document.id) ||
        document.status !== 'ACTIVE'
      ) {
        continue;
      }

      // auto-add heading (if grouping is active)
      if (this.state.selectedDocumentExport.configuration.groupByLabels) {
        if (
          i === 0 ||
          added === 0 ||
          getMainLabel(documents[i - 1].labels, labelService.getLabels()).id !== getMainLabel(document.labels, labelService.getLabels()).id
        ) {
          const newHeader = this.getDocumentLabelHeading(document);
          // only add the heading if it doesn't exist yet
          if (!this.state.selectedDocumentExport.documentEntries.find((e) => e.type === EntryType.HEADING && e?.labelId === newHeader.labelId)) {
            this.state.selectedDocumentExport.documentEntries.push(newHeader);
          }
        }
      }

      // add entry
      this.state.selectedDocumentExport.documentEntries.push({
        type: EntryType.DOCUMENT,
        documentId: document.id,
        sourceFileId: document.sourceFileId,
        fileReference: document.sourceFileUploadFilename,
      });

      added++;
    }

    if (added > 0) {
      await this.saveDocuments();
    }

    // see above
    if (resetLoading) {
      this.setLoading(false);
    }

    return added;
  }

  async addDocumentsByPredefinedLabelSelection() {
    const autoIncludeLabels = Array.from(labelService.getLabels().values()).filter(
      (l) =>
        (this.state.selectedDocumentExport.configuration.predefinedLabelSelection.includes(l.id) ||
          this.state.selectedDocumentExport.configuration.predefinedLabelSelection.includes('*')) &&
        !this.state.selectedDocumentExport.configuration.blacklistedLabels.includes(l.id),
    );

    const labelIds = autoIncludeLabels.map((l) => l.id);
    const allLabelIds = [];
    for (const labelId of labelIds) {
      const sublabels = labelService.getAllSublabels(labelId);
      const filteredSublabels = sublabels.filter((l) => !this.state.selectedDocumentExport.configuration.blacklistedLabels.includes(l.id));
      allLabelIds.push(labelId, ...filteredSublabels.map((l) => l.id));
    }

    const matchingDocuments = documentService.getDocuments().filter((d) => d.labels && d.labels.some((id) => allLabelIds.includes(id)));

    const documentIds = matchingDocuments.map((d) => d.id);
    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];

    const added = await this.addDocuments(documentIds);

    // notify user
    if (added > 0) {
      const undoFn = async () => {
        this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      };
      if (added === documentIds.length) {
        appService.info($t('CaseDetail.Export.documentsAddedInfoMessage'), undoFn);
      } else {
        appService.info($t('CaseDetail.Export.documentNotYetAvailableAddedInfoMessage'), undoFn);
      }
    } else if (added === 0 && documentIds.length > 0) {
      appService.info($t('CaseDetail.Export.noDcumentsAddedInfoMessage'));
    }

    this.setLoading(false);
  }

  async addFilteredDocuments() {
    this.setLoading(true);

    const filteredDocuments = documentService.getFilteredDocuments();

    // validate against label balacklist
    const { blacklistedLabels } = this.state.selectedDocumentExport.configuration;
    const filteredByBlacklistedDoctypes = filteredDocuments.filter((d) => !this.areDocLabelsInList(d.labels, blacklistedLabels));

    // get timeline documents, except deleted ones
    const timelineDocumentIds = filteredByBlacklistedDoctypes.filter((d) => d.status === 'ACTIVE').map((d) => d.id);

    // store current documentIds before change to allow undo action
    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    const added = await this.addDocuments(timelineDocumentIds);

    // notify user
    if (added > 0) {
      const undoFn = async () => {
        this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      };
      if (added === timelineDocumentIds.length) {
        appService.info($t('CaseDetail.Export.addedAllVisibleDocumentsInfoMessage'), undoFn);
      } else {
        appService.info($t('CaseDetail.Export.addedDocumentsWithLimitationsInfoMessage'), undoFn);
      }
    } else if (added === 0 && timelineDocumentIds.length > 0) {
      appService.info($t('CaseDetail.Export.documentsWasNotAddedInfoMessage'));
    } else {
      appService.info($t('CaseDetail.Export.noVisibleDocumentsInfoMessage'));
    }

    this.setLoading(false);

    $a.l($a.e.EXPORT_DOCS_ADD_ALL);
  }

  async addAllDocumentsWithLabel(labelId, isTypeOther) {
    // sanity check
    if (!labelId) return;

    this.setLoading(true);

    const label = labelService.getLabel(labelId);
    const labelSublabels = labelService.getAllSublabels(labelId);
    const filteredSublabels = labelSublabels.filter(
      (l) => !this.areDocLabelsInList([l.id], this.state.selectedDocumentExport.configuration.blacklistedLabels),
    );

    // compute label ids
    const labelIds = !label || isTypeOther || filteredSublabels.length === 0 ? [labelId] : [labelId, ...filteredSublabels.map((l) => l.id)];
    // filter
    let filteredDocuments = documentService.getDocuments().filter((d) => d.labels && d.labels.some((id) => labelIds.includes(id)));

    const folderFilter = documentFilterService.currentFilterValue('folder');
    if (folderFilter.length > 0) {
      filteredDocuments = filteredDocuments.filter((d) => folderFilter.includes(d.metadata.FOLDER.value));
    }

    const documentIds = filteredDocuments.map((d) => d.id);
    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];

    const added = await this.addDocuments(documentIds);

    // notify user
    if (added > 0) {
      const undoFn = async () => {
        this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      };
      if (added === documentIds.length) {
        appService.info($t('CaseDetail.Export.documentsAddedInfoMessage'), undoFn);
      } else {
        appService.info($t('CaseDetail.Export.documentNotYetAvailableAddedInfoMessage'), undoFn);
      }
    } else if (added === 0 && documentIds.length > 0) {
      appService.info($t('CaseDetail.Export.noDcumentsAddedInfoMessage'));
    }

    this.setLoading(false);
  }

  async addAllImportantDocuments() {
    this.setLoading(true);

    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];

    let filteredDocuments = documentService.getDocuments().filter((d) => d.metadata.IMPORTANT.value === 'true');
    const folderFilter = documentFilterService.currentFilterValue('folder');
    if (folderFilter.length > 0) {
      filteredDocuments = filteredDocuments.filter((d) => folderFilter.includes(d.metadata.FOLDER.value));
    }

    // validate against doctype balacklist
    const { blacklistedLabels } = this.state.selectedDocumentExport.configuration;
    const filteredByBlacklistedDoctypes = filteredDocuments.filter((d) => !this.areDocLabelsInList(d.labels, blacklistedLabels));

    const documentIds = filteredByBlacklistedDoctypes.map((d) => d.id);

    const added = await this.addDocuments(documentIds);

    // notify user
    if (added > 0) {
      const undoFn = async () => {
        this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
        await this.saveDocuments();
      };
      if (added === documentIds.length) {
        appService.info($t('CaseDetail.Export.documentsAddedInfoMessage'), undoFn);
      } else {
        appService.info($t('CaseDetail.Export.documentNotYetAvailableAddedInfoMessage'), undoFn);
      }
    } else if (added === 0 && documentIds.length > 0) {
      appService.info($t('CaseDetail.Export.noDcumentsAddedInfoMessage'));
    }

    this.setLoading(false);
  }

  getDocumentLabelHeading(document) {
    const label = getMainLabel(document.labels, labelService.getLabels());
    const language = this.state.selectedDocumentExport.configuration.locale?.split('-')[0] || 'de';
    // NOTE(ndv): custom labels are not included in the entityservice entries
    const title = entityService.DOC_DOCTYPE_LABELS_RAW.metadata.byId[label.id]?.title[language] || label?.title || $t('Common.unknown', language);

    return {
      type: EntryType.HEADING,
      text: title,
      documentId: null,
      labelId: label?.id,
    };
  }

  async insertLabelsHeadings() {
    this.setLoading(true);

    const { documentEntries } = this.state.selectedDocumentExport;
    documentEntries.sort((a, b) => this.sortByLabel(a, b, 'documentId'));

    // NOTE(ndv): we use a map to avoid changing the array in place which causes issues with the loop
    let added = 0;
    for (let i = 0; i < documentEntries.length; i++) {
      const entry = documentEntries[i];
      if (entry.type === EntryType.HEADING) continue;

      const document = documentService.getDocumentsCache().get(entry.documentId);

      if (added === 0) {
        const newHeader = this.getDocumentLabelHeading(document);
        documentEntries.splice(i, 0, newHeader);
        added++;
        continue;
      }

      const prevEntry = documentEntries[i - 1];
      if (prevEntry.type === EntryType.HEADING) continue;

      const prevDocument = documentService.getDocumentsCache().get(prevEntry.documentId);
      if (getMainLabel(prevDocument.labels, labelService.getLabels()).id === getMainLabel(document.labels, labelService.getLabels()).id) {
        continue;
      }

      const newHeader = this.getDocumentLabelHeading(document);
      documentEntries.splice(i, 0, newHeader);
      added++;
    }

    this.setLoading(false);
  }

  async removeLabelsHeadings() {
    this.setLoading(true);

    this.state.selectedDocumentExport.documentEntries = this.state.selectedDocumentExport.documentEntries.filter(
      (e) => e.type === EntryType.DOCUMENT || (e.type === EntryType.HEADING && !e?.labelId),
    );

    this.setLoading(false);
  }

  async addHeading(index) {
    const newDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    newDocumentEntries.splice(index, 0, {
      type: EntryType.HEADING,
      text: '',
      documentId: null,
    });

    this.state.selectedDocumentExport.documentEntries = newDocumentEntries;
    await this.saveDocuments();
    $a.l($a.e.EXPORT_DOCS_HEADING);
  }

  async updateDocumentEntry(index, newData) {
    this.state.selectedDocumentExport.documentEntries[index] = {
      ...this.state.selectedDocumentExport.documentEntries[index],
      ...newData,
    };
  }

  async deleteAllVisibleDocuments() {
    this.setLoading(true);

    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    this.state.selectedDocumentExport.documentEntries = [...this.state.selectedDocumentExport.documentEntries].filter(
      (entry) => !this.isVisible(entry.documentId),
    );
    await this.saveDocuments();

    appService.info($t('CaseDetail.Export.allVisibleDocumentsRemovedInfoMessage'), async () => {
      this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
      await this.saveDocuments();
    });

    this.setLoading(false);

    $a.l($a.e.EXPORT_DOCS_CLEAR);
  }

  async deleteDocument(documentId) {
    if (!this.state.selectedDocumentExport.documentEntries.find((e) => e.documentId === documentId)) return;

    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    this.state.selectedDocumentExport.documentEntries = [...this.state.selectedDocumentExport.documentEntries].filter(
      (e) => e.documentId !== documentId,
    );
    await this.saveDocuments();

    appService.info($t('CaseDetail.Export.documentRemovedInfoMessage'), async () => {
      this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
      await this.saveDocuments();
    });

    $a.l($a.e.EXPORT_DOCS_DELETE);
  }

  async removeDocumentEntry(index) {
    if (index > this.state.selectedDocumentExport.documentEntries.length || index < 0) return;

    const oldDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];
    const newDocumentEntries = [...this.state.selectedDocumentExport.documentEntries];

    newDocumentEntries.splice(index, 1);

    this.state.selectedDocumentExport.documentEntries = newDocumentEntries;
    await this.saveDocuments();

    appService.info($t('CaseDetail.Export.headlineRemovedInfoMesssage'), async () => {
      this.state.selectedDocumentExport.documentEntries = oldDocumentEntries;
      await this.saveDocuments();
    });

    $a.l($a.e.EXPORT_DOCS_HEADING);
  }

  refreshList() {
    if (!this.state.selectedDocumentExport) {
      return;
    }
    const oldDocumentLength = this.state.selectedDocumentExport.documentEntries.length;
    this.state.selectedDocumentExport.documentEntries = this.state.selectedDocumentExport?.documentEntries?.filter((e) => {
      if (e.type === EntryType.DOCUMENT) {
        return documentService.getDocumentsCache().has(e.documentId);
      }
      return true;
    });
    if (oldDocumentLength !== this.state.selectedDocumentExport.documentEntries.length) {
      this.saveDocuments();
    }
  }

  isVisible(documentId) {
    if (!this.state.labelFilter) {
      return true;
    }
    const labelId = this.state.labelFilter.id;
    const doc = documentService.getDocumentsCache().get(documentId);
    if (labelId === null && (!doc.labels || doc.labels.length === 0)) {
      return true;
    }
    return doc.labels.some((lid) => lid === labelId);
  }

  hasVisibileDocuments() {
    return this.state.selectedDocumentExport?.documentEntries
      ?.filter((e) => e.type === EntryType.DOCUMENT)
      ?.some((e) => this.isVisible(e.documentId));
  }

  hasHeadings() {
    return this.state.selectedDocumentExport?.documentEntries?.filter((e) => e.type === EntryType.HEADING)?.length > 0;
  }

  async setSorting(sortingModeKey) {
    if (!SortingModes[sortingModeKey]) return;

    this.state.selectedDocumentExport.configuration.sorting = sortingModeKey;

    $a.l($a.e.EXPORT_DOCS_SORT_MODE);
    await this.saveDocuments();
  }

  async setGroupByLabels(groupByLabels) {
    this.state.selectedDocumentExport.configuration.groupByLabels = !!groupByLabels;
    $a.l($a.e.EXPORT_DOCS_GROUP_BY_LABELS);

    this.sort();

    if (groupByLabels) {
      this.insertLabelsHeadings();
    } else {
      this.removeLabelsHeadings();
    }

    await this.saveDocuments();
  }

  sort() {
    const { sorting, groupByLabels } = this.state.selectedDocumentExport.configuration;

    if (sorting === SortingModes.MANUAL.key) return;

    // store each non-DOCUMENT entry and the document to whom it belongs (will be readded later): map<documentId, entry>
    const documentHeadings = new Map();
    const fixedHeadings = new Map();
    for (let i = 0; i < this.state.selectedDocumentExport.documentEntries.length; i++) {
      const entry = this.state.selectedDocumentExport.documentEntries[i];
      if (entry.type === EntryType.DOCUMENT) continue;
      if (i === this.state.selectedDocumentExport.documentEntries.length - 1) {
        fixedHeadings.set(i, entry);
        continue;
      }

      const nextEntry = this.state.selectedDocumentExport.documentEntries[i + 1];
      if (nextEntry.documentId) {
        documentHeadings.set(nextEntry.documentId, entry);
      } else {
        fixedHeadings.set(i, entry);
      }
    }

    // copy list of document entries of type DOCUMENT (we only sort those)
    const documentEntriesToSort = [...this.state.selectedDocumentExport.documentEntries.filter((e) => e.type === EntryType.DOCUMENT)];

    // sort them based on sorting
    if (sorting.startsWith('ISSUE_DATE') || sorting.startsWith('RECEIPT_DATE')) {
      this.sortByDate(documentEntriesToSort, sorting, groupByLabels);
    } else if (sorting.startsWith('PAGINATION_NO')) {
      this.sortByPaginationNo(documentEntriesToSort, sorting, groupByLabels);
    }

    // insert non-DOCUMENT entries
    for (const [documentId, entry] of documentHeadings.entries()) {
      const index = documentEntriesToSort.findIndex((e) => e.documentId === documentId);
      documentEntriesToSort.splice(index, 0, entry);
    }

    for (const [index, entry] of fixedHeadings.entries()) {
      documentEntriesToSort.splice(index, 0, entry);
    }

    this.state.selectedDocumentExport.documentEntries = documentEntriesToSort;
  }

  // NOTE: list of DocumentEntries
  sortByDate(list, sorting, groupByLabels) {
    list.sort((a, b) => {
      // grouping by label
      if (groupByLabels) {
        const order = this.sortByLabel(a, b);
        if (order !== 0) return order;
      }

      let aDocument = documentService.getDocumentsCache().get(a.documentId);
      let bDocument = documentService.getDocumentsCache().get(b.documentId);
      let key;
      if (sorting.endsWith('_DESC')) {
        [aDocument, bDocument] = [bDocument, aDocument];
        key = sorting.replace('_DESC', '');
      } else {
        key = sorting.replace('_ASC', '');
      }
      return aDocument.metadata[key].value.localeCompare(bDocument.metadata[key].value);
    });
  }

  // NOTE: list of DocumentEntries
  sortByPaginationNo(list, sorting, groupByLabels) {
    list.sort((a, b) => {
      // grouping by label
      if (groupByLabels) {
        const order = this.sortByLabel(a, b);
        if (order !== 0) return order;
      }

      const aPaginationNo = a.paginationNo;
      const bPaginationNo = b.paginationNo;

      if (sorting.endsWith('_DESC')) {
        return aPaginationNo.localeCompare(bPaginationNo);
      }
      return bPaginationNo.localeCompare(aPaginationNo);
    });
  }

  // NOTE: a, b are DocumentEntries or Documents (for the latter, we need to pass the right documentIdProperty)
  sortByLabel(a, b, documentIdProperty = 'documentId') {
    const aDocument = documentService.getDocumentsCache().get(a[documentIdProperty]);
    const bDocument = documentService.getDocumentsCache().get(b[documentIdProperty]);
    const aLabel = getMainLabel(aDocument.labels, labelService.getLabels());
    const bLabel = getMainLabel(bDocument.labels, labelService.getLabels());

    if (aLabel === bLabel) return 0;
    if (!aLabel && bLabel) return -1;
    if (aLabel && !bLabel) return 1;

    // NOTE(ndv): give priority to sorting defined by predefined labels
    const indexOfLabelA = this.state.selectedDocumentExport.configuration.predefinedLabelSelection.indexOf(aLabel.id);
    const indexOfLabelB = this.state.selectedDocumentExport.configuration.predefinedLabelSelection.indexOf(bLabel.id);
    if (indexOfLabelA !== -1 && indexOfLabelB !== -1) return indexOfLabelA - indexOfLabelB;
    if (indexOfLabelA !== -1 && indexOfLabelB === -1) return -1;
    if (indexOfLabelA === -1 && indexOfLabelB !== -1) return 1;

    const aSorting = aLabel.sorting;
    let bSorting = bLabel.sorting;
    // NOTE(mba): the main label, e.g. "Medizinisch allgemein", should always be after the sub-labels
    if (aLabel.sorting && !aLabel.sorting.includes('.')) {
      aLabel.sorting += '.99';
    }
    if (bSorting && !bSorting.includes('.')) {
      bSorting += '.99';
    }

    if (aSorting && bSorting) return aSorting.localeCompare(bSorting);
    if (aSorting && !bSorting) return -1;
    if (!aSorting && bSorting) return 1;

    return aLabel.title.localeCompare(bLabel.title);
  }

  changeLabelFilter(value) {
    this.state.labelFilter = value;
    if (value) {
      documentFilterService.setFilter('labels', value.id ? [value.id] : []);
    }
    $a.l($a.e.EXPORT_DOCS_FILTER);
  }

  updateLabelFilterOptions() {
    const labelMap = new Map();
    let otherCount = 0;

    for (const entry of this.state.selectedDocumentExport.documentEntries) {
      if (entry.type !== EntryType.DOCUMENT) continue;

      const doc = documentService.getDocumentsCache().get(entry.documentId);

      if (!doc.labels || doc.labels?.length === 0) {
        otherCount++;
      }
      for (const label of doc.labels) {
        if (labelMap.has(label)) {
          labelMap.set(label, labelMap.get(label) + 1);
        } else {
          labelMap.set(label, 1);
        }
      }
    }

    const options = [];
    for (const [labelId, count] of labelMap.entries()) {
      const label = labelService.getLabel(labelId);
      const text = `${label.title} (${count})`;
      options.push({
        text,
        id: labelId,
        labelText: label.title,
        labelColor: label.color,
        labelIcon: label.icon,
      });
    }

    options.sort((a, b) => a.labelText.toLowerCase().localeCompare(b.labelText.toLowerCase()));

    if (otherCount > 0) {
      options.push({
        text: $t('CaseDetail.Export.otherCount', [otherCount]),
        id: null,
        labelText: $t('CaseDetail.Export.others'),
        labelColor: 'primary',
        labelIcon: 'mdi-label-off',
      });
    }
    this.state.labelFilterOptions = options;
  }

  selectDocument(documentId) {
    this.state.selectedDocumentId = documentId;
    broadcastEventBus.emit('DOCUMENT_SELECTED_EVENT', { docId: documentId, page: 0, scroll: false, forceShow: false, iterate: false });
  }

  async selectDocumentAtIndex(index) {
    const entry = this.state.selectedDocumentExport.documentEntries[index];
    if (!entry) return;
    if (entry.type === EntryType.DOCUMENT) {
      await this.selectDocument(entry.documentId);
    }
  }

  updateMaps() {
    // fill reference map
    this.documentExportsMap = new Map();

    for (const documentExport of this.state.documentExportsList) {
      this.documentExportsMap.set(documentExport.id, documentExport);
    }

    // build recipient map
    this.state.documentExportHierarchyMap = new Map();
    for (const documentExport of this.state.documentExportsList) {
      // parent export
      if (!documentExport.previousExportId) {
        if (!this.state.documentExportHierarchyMap.has(documentExport.id)) {
          this.state.documentExportHierarchyMap.set(documentExport.id, [documentExport]);
          continue;
        }

        this.state.documentExportHierarchyMap.get(documentExport.id).push(documentExport);
        continue;
      }

      // export update
      if (this.state.documentExportHierarchyMap.has(documentExport.previousExportId)) {
        this.state.documentExportHierarchyMap.get(documentExport.previousExportId).push(documentExport);
      } else {
        this.state.documentExportHierarchyMap.set(documentExport.previousExportId, [documentExport]);
      }
    }
  }

  updateLocalExport(documentExport) {
    const index = this.state.documentExportsList.findIndex((de) => de.id === documentExport.id);
    if (index === -1) return;

    this.state.documentExportsList[index] = documentExport;
    this.updateMaps();
  }

  /* Config Panel */
  getFilenamesForSelect() {
    const filenames = [];
    const templates = this.state.selectedDocumentExport.configuration.filenameTemplates.split('\n');

    for (const template of templates) {
      const parsed = this.parseFilenameTemplate(template, this.state.selectedDocumentExport);
      if (parsed.trim() !== '') {
        filenames.push({ value: template, text: parsed });
      }
    }

    return filenames;
  }

  parseFilenameTemplate(template, documentExport) {
    const actsStart = documentExport?.previousExportId ? documentExport.prevDocumentIds.length + 1 : 1;
    const actsEnd = documentExport?.previousExportId
      ? documentExport.prevDocumentIds.length + documentExport.documentEntries.length
      : documentExport.documentEntries.length;

    const patterns = {
      '{actsRange}': `${actsStart}-${actsEnd}`,
      '{initials}': `${this.currentCase.displayLabel
        .split(' ')
        .map((s) => s.charAt(0))
        .join('')}`,
      '{date}': dayjs().format('YYYY-MM-DD'),
      '{reference}': this.currentCase.reference,
    };

    for (const [search, replace] of Object.entries(patterns)) {
      template = template.replace(search, replace);
    }
    return template;
  }

  getConfigurationTitleSuffixes() {
    return {
      title: `${this.currentCase.displayLabel}`,
      subtitle: `${formatToLocale(dayjs())}`,
    };
  }

  areDocLabelsInList(labels, list) {
    for (const labelId of labels) {
      if (list.includes(labelId)) {
        return true;
      }
    }
    return false;
  }

  getProcessedEmailSubject(exportConfig) {
    const prefix = $t('CaseDetail.Export.Settings.subjectPrefix', {
      user: authService.state.user.name,
      tenant: authService.state.data.tenant.tenantConfig.brandingConfig.companyName,
    });

    const processedSubject = exportConfig.emailSubject
      .replace('{caseReference}', this.currentCase.reference)
      .replace('{reference}', this.currentCase.reference)
      .replace('{name}', this.currentCase.displayLabel);

    return `${prefix} ${processedSubject}`;
  }

  /* Navigation */
  async back() {
    this.state.view = ExportView.ExportList;
    this.state.isLoading = false;
    this.state.isFirstPreview = true;
    this.state.allowSave = false;
    this.state.labelFilter = null;
    this.state.labelFilterOptions = [];
    $a.l($a.e.EXPORT_BACK);
  }

  editDraft(documentExport) {
    this.state.selectedDocumentExport = documentExport;
    this.overrideDefaultConfig();
    this.refreshList();
    this.updateLabelFilterOptions();
    this.state.view = ExportView.DocumentSelection;
    $a.l($a.e.EXPORT_EDIT);
  }

  dragStart() {
    if (this.state.view === ExportView.DocumentSelection) {
      this.state.showDropOverlay = true;
    }
  }

  dragEnd() {
    this.state.showDropOverlay = false;
  }

  destroy() {
    this.state.documentExportsList = null;
    this.state.documentExportHierarchyMap = null;
    this.documentExportsMap = null;
    this.currentCase = null;

    // view
    this.state.isLoading = false;
    this.state.isFirstPreview = true;
    this.state.error = null;
    this.state.view = ExportView.ExportList;

    this.state.selectedDocumentExport = null;

    // filter
    this.state.labelFilter = null;
    this.state.labelFilterOptions = [];
  }
}

export default new ExportService();
export const ExportServiceClass = ExportService;
