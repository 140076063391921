<template>
  <div class="d-flex flex-row flex-nowrap fill-height" data-testid="caseList">
    <!-- content -->
    <div class="content">
      <!-- filters -->
      <v-container>
        <div class="filters d-flex flex-column flex-lg-row align-lg-center ga-lg-2" color="transparent" flat data-testid="subHeader">
          <div class="order-2 mt-3 mt-lg-0 order-lg-1 d-flex flex-column flex-md-row align-md-center ga-md-4">
            <v-text-field
              v-model="searchTerm"
              autofocus
              class="case-search"
              clearable
              density="compact"
              variant="outlined"
              hide-details
              :style="{ minWidth: '240px' }"
              :placeholder="$t('CaseList.searchByCaseName')"
              prepend-inner-icon="mdi-magnify"
              data-testid="input_caseSearch"
              @click:clear="searchTerm = ''"
            />
            <v-chip-group v-model="activeFilters" selected-class="bg-primary" class="flex-nowrap" multiple>
              <v-chip label :value="CASELIST_FILTERS.OWN" data-testid="btn_showOnlyOwnCases" class="ml-2">
                <v-icon size="small" start> mdi-account-outline </v-icon>
                {{ $t('Common.CasesFilterToggle.myCases') }}

                <v-badge class="ml-2" :content="$n(ownCasesCount)" inline />
              </v-chip>

              <v-chip label :value="CASELIST_FILTERS.NEW" data-testid="btn_showOnlyNewCases" class="ml-2">
                <v-icon size="small" start> mdi-new-box </v-icon>
                {{ $t('Common.CasesFilterToggle.withNewDocs') }}
                <v-badge class="ml-2" :content="$n(withNewDocsCount)" inline />
              </v-chip>

              <v-chip
                v-if="$hasFeature('ENABLE_INACTIVITY_CHECK')"
                label
                :value="CASELIST_FILTERS.INACTIVE"
                data-testid="btn_showOnlyOwnInactiveCases"
                class="ml-2"
              >
                <v-icon size="small" start> mdi-clock-alert-outline </v-icon>
                {{ $t('Common.CasesFilterToggle.inactiveCases') }}
                <v-badge class="ml-2" :content="$n(inactiveCasesCount)" inline />
              </v-chip>

              <v-chip
                v-if="(!$isExternalUser() && $hasFeature('ENABLE_MANUAL_CASE_MANAGEMENT')) || $hasPermission('LEGALCASE_UD')"
                label
                :value="CASELIST_FILTERS.ARCHIVED"
                class="mr-0 ml-2"
                data-testid="btn_showArchived"
              >
                <v-icon size="small" start> mdi-archive-outline </v-icon>
                {{ $t('Common.CasesFilterToggle.archivedCases') }}
                <v-badge class="ml-2" :content="$n(archivedCasesCount)" inline />
              </v-chip>
            </v-chip-group>
          </div>

          <div class="d-flex order-1 order-lg-2 flex-grow-1">
            <l-menu location="bottom" transition="slide-y-transition">
              <template #activator="{ props: menuProps }">
                <v-btn
                  v-tippy="$t('CaseList.sortCases')"
                  variant="text"
                  data-testid="btn_sort_cases"
                  :icon="sortModes[currentMode].icon"
                  v-bind="menuProps"
                />
              </template>

              <v-list :selected="[currentMode]" density="compact" class="pa-0" mandatory @update:selected="currentMode = $event[0]">
                <v-list-item v-for="(mode, key) in sortModes" :key="key" :value="key" :data-testid="key" class="text-body-2">
                  <v-icon start>
                    {{ mode.icon }}
                  </v-icon>
                  {{ $t(`CaseList.SortModes.${key}`) }}
                </v-list-item>
              </v-list>
            </l-menu>

            <v-spacer />

            <template v-if="$isLegaliAdmin()">
              <v-btn
                v-tippy="'Processing'"
                icon="mdi-pipe"
                :color="activeFilters.includes(CASELIST_FILTERS.IN_PROGRESS) ? 'warning' : ''"
                variant="text"
                @click="
                  activeFilters.includes(CASELIST_FILTERS.IN_PROGRESS)
                    ? (activeFilters = activeFilters.filter((filter: any) => filter !== CASELIST_FILTERS.IN_PROGRESS))
                    : activeFilters.push(CASELIST_FILTERS.IN_PROGRESS)
                "
              />
              <v-btn
                v-tippy="'Interaction from legal-i required'"
                icon="mdi-alert-circle"
                :color="activeFilters.includes(CASELIST_FILTERS.INTERACTION_REQUIRED) ? 'warning' : ''"
                variant="text"
                @click="
                  activeFilters.includes(CASELIST_FILTERS.INTERACTION_REQUIRED)
                    ? (activeFilters = activeFilters.filter((filter: any) => filter !== CASELIST_FILTERS.INTERACTION_REQUIRED))
                    : activeFilters.push(CASELIST_FILTERS.INTERACTION_REQUIRED)
                "
              />
            </template>
            <v-btn
              v-if="$hasPermission('ACCESS_CASE_DETAIL_LEGALCASE_ACCESS') && $hasFeature('ENABLE_COLLABORATION')"
              v-tippy="$t('CaseList.viewCasesWithExternalAccess')"
              :icon="USER_ROLES.EXTERNAL_BASIC_ROLE.icon"
              :color="activeFilters.includes(CASELIST_FILTERS.LEGALCASE_ACCESS) ? 'accent' : ''"
              variant="text"
              @click="
                activeFilters.includes(CASELIST_FILTERS.LEGALCASE_ACCESS)
                  ? (activeFilters = activeFilters.filter((filter: any) => filter !== CASELIST_FILTERS.LEGALCASE_ACCESS))
                  : activeFilters.push(CASELIST_FILTERS.LEGALCASE_ACCESS)
              "
            />
            <v-btn
              v-tippy="$t('Common.refreshList')"
              class="mr-2"
              icon="mdi-refresh"
              variant="text"
              :loading="legalCaseService.isRefreshing()"
              @click="refreshCases()"
            />

            <v-btn
              v-if="$hasFeature('ENABLE_MANUAL_CASE_MANAGEMENT')"
              v-blur
              v-tippy="$t('Common.createCase')"
              :disabled="isLoading"
              color="primary"
              variant="elevated"
              icon="mdi-plus"
              rounded="circle"
              data-testid="btn_newCase"
              @click="
                showAddCaseDialog = true;
                $a.l($a.e.CASELIST_ADD);
              "
            />
          </div>
        </div>
      </v-container>

      <template v-if="!cases.length">
        <!-- empty states -->

        <!-- error loading cases -->
        <v-container v-if="legalCaseService.isErrored()">
          <div class="px-0 py-16 mt-16 mx-auto text-center">
            <v-avatar size="64">
              <v-icon size="xx-large" color="error"> mdi-alert-circle-outline </v-icon>
            </v-avatar>
            <div class="text-h6">{{ $t('CaseList.unableToLoad') }}</div>
            <v-btn class="ma-2 mt-4" color="primary" variant="outlined" @click="reload">
              {{ $t('App.Error.reloadApp') }}
            </v-btn>
          </div>
        </v-container>

        <!-- init load -->
        <v-container v-else-if="legalCaseService.isLoading()">
          <v-row align-center mb-12 wrap>
            <CaseTileSkeleton v-for="casePlaceholder in 36" :key="casePlaceholder" />
          </v-row>
        </v-container>

        <!-- no search/filter results -->
        <v-container v-else-if="legalCaseService.state.cases.length">
          <div class="px-0 py-16 mt-16 mx-auto text-center">
            <v-avatar size="64">
              <v-icon size="xx-large"> mdi-circle-off-outline </v-icon>
            </v-avatar>
            <div class="text-h6">
              {{ $t('Common.noResults') }}
            </div>
          </div>
        </v-container>

        <!-- no cases -->
        <v-container v-else>
          <v-card class="pa-9 mx-auto text-center d-flex align-center flex-column no-cases-state-card outlined" flat width="570">
            <v-img class="mt-3" max-height="50" src="/assets/logo.svg" />
            <div class="text-h4 mt-8">
              {{ $t('CaseList.welcomeTitle') }}
            </div>
            <div v-if="$hasRole('EXTERNAL_BASIC_ROLE')" class="text-body-1 mt-8">
              {{ $t('CaseList.noSharedCasesForYou') }}
              <br /><br />
              {{ $t('CaseList.asSoonAsSomeoneShares') }}
            </div>
            <template v-else>
              <div class="text-body-1 mt-8">
                {{ $t('CaseList.noCasesAtTheMoment') }}
                <br /><br />
                {{ $t('CaseList.asSoonAsYourTeamCreateCaseItWillAppear') }}
              </div>

              <v-btn v-if="$hasPermission('LEGALCASE_UD')" class="mt-12" color="primary" variant="flat" @click.stop="showAddCaseDialog = true">
                <v-icon start> mdi-plus </v-icon>
                {{ $t('CaseList.createYourFirstCase') }}
              </v-btn>
              <v-sheet v-else class="text-body-2 mt-12">
                {{ $t('CaseList.noPermissionToCreateCaseOrUploadFile') }}
              </v-sheet>
            </template>
          </v-card>
        </v-container>
      </template>
      <template v-else>
        <!-- cases exist -->

        <!-- recent cases -->
        <v-container v-if="quickAccessCases.length > 0 && !casesFiltered">
          <v-list-subheader class="text-subtitle-1" data-testid="lbl_quickAccess">
            {{ $t('CaseList.quickAccess') }}
          </v-list-subheader>
          <v-row align-center mb-1 wrap data-testid="cases_list_fast">
            <v-col v-for="legalCase in quickAccessCases" :key="legalCase.id" cols="12" lg="3" md="4" sm="6" xl="2">
              <v-card
                :to="{
                  name: 'case',
                  params: { tenant: authService.state.data?.tenant.canonicalName, caseId: legalCase.id },
                }"
                flat
                class="outlined"
              >
                <v-card-text>
                  <div v-tippy="$t('Common.openCase')" class="text-h6 font-weight-regular pb-0 mb-1 text-truncate on-surface data-hj-suppress">
                    <v-icon start size="x-small"> mdi-open-in-app </v-icon>
                    {{ legalCase.displayLabel }}

                    <v-icon v-if="hasNewSourceFiles(legalCase)" size="small" class="ml-2"> mdi-new-box </v-icon>
                    <v-icon v-else size="small" class="ml-2"> mdi-history </v-icon>
                    <!-- Icon for new docs -->
                  </div>

                  <span v-if="legalCase.recentAction === 'OPENED'" class="text-truncate">
                    {{ $t('CaseList.caseOpened', [formatToHumanDuration(legalCase.recentTimestamp)]) }}
                  </span>
                  <div v-else class="text-truncate">
                    {{ $t('CaseList.lastFileAdded', [formatToHumanDuration(legalCase.recentTimestamp)]) }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <!-- cases -->
        <v-container>
          <template v-if="!activeFilters.includes(CASELIST_FILTERS.ARCHIVED)">
            <v-row align-center mb-12 wrap data-testid="cases_list_open">
              <v-col v-for="legalCase in readyCases" :key="legalCase.id" cols="12" lg="3" md="4" sm="6" xl="2">
                <v-lazy
                  :options="{
                    threshold: 0.2,
                  }"
                  min-height="172"
                  transition="fade-transition"
                >
                  <CaseTile
                    :legal-case="legalCase"
                    @show-edit-case-dialog="showEditCaseDialog($event)"
                    @show-file-manager="showFileManager($event)"
                    @show-edit-demo-information-dialog="showDemoInformation($event)"
                    @show-data-label-dialog="showDataLabel($event)"
                  />
                </v-lazy>
              </v-col>
              <!-- indicate that there are still more cases loading -->
              <template v-if="legalCaseService.isLoading()">
                <CaseTileSkeleton v-for="casePlaceholder in 12" :key="casePlaceholder" />
              </template>
            </v-row>
          </template>

          <!-- in progress or archive -->
          <template v-if="notReadyCases.length > 0">
            <v-list-subheader v-if="!activeFilters.includes(CASELIST_FILTERS.ARCHIVED)" class="text-subtitle-1">
              {{ $t('CaseList.inProcessing') }}
            </v-list-subheader>
            <v-row
              align-center
              wrap
              :data-testid="!activeFilters.includes(CASELIST_FILTERS.ARCHIVED) ? 'cases_list_inProgress' : 'cases_list_archived'"
            >
              <v-col v-for="legalCase in notReadyCases" :key="legalCase.id" cols="12" lg="3" md="4" sm="6" xl="2">
                <CaseTile
                  :legal-case="legalCase"
                  @show-edit-case-dialog="showEditCaseDialog($event)"
                  @show-file-manager="showFileManager($event)"
                  @show-data-label-dialog="showDataLabel($event)"
                />
              </v-col>
            </v-row>
          </template>
        </v-container>
      </template>
    </div>

    <AddEditCase
      :editing-mode="addEditCaseDialogUseEditingMode"
      :input-case="inputCase"
      :visible="showAddCaseDialog"
      @close="resetDialog()"
      @switch-case="showFileManager($event)"
    />

    <template v-if="inputCase">
      <FileManager :legal-case="inputCase" :visible="showFileManagerDialog" @close="showFileManagerDialog = false" @show-panel="showSheet($event)" />
      <EditDemoInformation :input-case="inputCase" :visible="showDemoInformationDialog" @close="showDemoInformationDialog = false" />
      <DataLabelDialog :input-case="inputCase" :visible="showDataLabelDialog" @close="showDataLabelDialog = false" />
    </template>

    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center pa-5" height="400px">
        <v-btn color="primary" icon variant="text" @click="sheet = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="text-left">
          <span class="font-weight-bold">{{ $t('CaseList.error') }}</span>
          <div v-dompurify-html="sheetContent" />
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { defineComponent } from 'vue';

import appService from '@/app/services/app.service';
import { detailViewPersistenceService } from '@/case-detail/services/detail.view.persistence.service';
import AddEditCase from '@/case-list/components/AddEditCase.vue';
import CaseTile from '@/case-list/components/CaseTile.vue';
import CaseTileSkeleton from '@/case-list/components/CaseTileSkeleton.vue';
import DataLabelDialog from '@/case-list/components/DataLabelDialog.vue';
import EditDemoInformation from '@/case-list/components/EditDemoInformation.vue';
import FileManager from '@/case-list/components/FileManager.vue';
import { type LegalCase, legalCaseService } from '@/case-list/services/legalcase.service';
import legalCaseSortService from '@/case-list/services/legalcase.sort.service';
import { authService } from '@/common/services/auth/auth.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import { formatToHumanDuration } from '@/common/services/date.utils';
import entityService from '@/common/services/entity.service';
import preferencesService from '@/common/services/preferences.service';
import { API } from '@/common/types/api.types';
import { ObjectValues } from '@/common/types/common.types';

dayjs.extend(isSameOrAfter);

const CASELIST_FILTERS = {
  OWN: 'OWN',
  NEW: 'NEW',
  ARCHIVED: 'ARCHIVED',
  INTERACTION_REQUIRED: 'INTERACTION_REQUIRED',
  IN_PROGRESS: 'IN_PROGRESS',
  LEGALCASE_ACCESS: 'LEGALCASE_ACCESS',
  INACTIVE: 'INACTIVE',
} as const;
type CaseListFilter = ObjectValues<typeof CASELIST_FILTERS>;

const preservedInUriFilters: CaseListFilter[] = ['OWN', 'NEW', 'INACTIVE', 'ARCHIVED'];

export default defineComponent({
  components: {
    DataLabelDialog,
    CaseTile,
    AddEditCase,
    EditDemoInformation,
    FileManager,
    CaseTileSkeleton,
  },

  data() {
    return {
      authService,
      showAddCaseDialog: false,
      showFileManagerDialog: false,
      showDemoInformationDialog: false,
      showDataLabelDialog: false,
      addEditCaseDialogUseEditingMode: false,
      inputCase: null as LegalCase | null,
      sheet: false,
      sheetContent: '',

      searchTerm: '',
      activeFilters: [] as CaseListFilter[],

      USER_ROLES: entityService.USER_ROLES,
      CASELIST_FILTERS,
      legalCaseService,
    };
  },

  computed: {
    currentMode: {
      get() {
        return legalCaseSortService.getSorting();
      },
      set(value: API.LegalCase.ListSorting) {
        legalCaseSortService.setSorting(value);
        this.$a.l(this.$a.e.CASELIST_SORT, { sortMode: value });
      },
    },
    sortModes() {
      return legalCaseSortService.sortModes;
    },
    cases() {
      let { cases } = legalCaseService.state;
      const { pinnedCases } = preferencesService.state.caseListPreferences;

      // filter by local filters
      if (this.searchTerm) {
        const terms = this.searchTerm.trim().toLowerCase().split(' ');
        cases = cases.filter((c) => {
          for (const term of terms) {
            if (
              c.displayLabel.toLowerCase().includes(term) ||
              c.pii.PII_COMPANY.value.toLowerCase().includes(term) ||
              c.id.startsWith(term) ||
              c.pii.CUSTOM_1.value.toLowerCase().startsWith(term) ||
              c.sourceFileIds.some((id) => id.startsWith(term)) ||
              c.sourceFileUploadFileNames.some((fn) => fn && fn.toLowerCase().startsWith(term.toLowerCase())) ||
              c.reference.includes(term)
            ) {
              return true;
            }
          }
          return false;
        });
      }
      if (this.activeFilters.includes('OWN')) {
        const owner = authService.state.userId;
        cases = cases.filter((c) => c.owner === owner);
      }
      if (this.activeFilters.includes('INACTIVE')) {
        cases = cases.filter((c) => !dayjs(c.inactive).isSameOrAfter(dayjs()));
      }
      if (this.activeFilters.includes('NEW')) {
        cases = cases.filter((c) => this.hasNewSourceFiles(c));
      }
      if (this.activeFilters.includes('INTERACTION_REQUIRED')) {
        cases = cases.filter((c) => !!c.pipelineStatus);
      }
      if (this.activeFilters.includes('LEGALCASE_ACCESS')) {
        cases = cases.filter((c) => dayjs(c.legalCaseAccessUntil).isSameOrAfter(dayjs()));
      }
      if (this.activeFilters.includes('IN_PROGRESS')) {
        cases = cases.filter((c) => !!c.inProgress);
      }
      if (this.activeFilters.includes('ARCHIVED')) {
        cases = cases.filter((c) => c.legalCaseStatus === 'ARCHIVED');
      } else {
        cases = cases.filter((c) => c.legalCaseStatus !== 'ARCHIVED');
      }
      const pinned = cases.filter((c) => pinnedCases.includes(c.id));
      const notPinned = cases.filter((c) => !pinnedCases.includes(c.id));

      // Combine the arrays such that pinned cases come first
      cases = [...pinned, ...notPinned];

      return cases;
    },
    withNewDocsCount() {
      return this.cases.filter((c) => this.hasNewSourceFiles(c)).length;
    },
    inactiveCasesCount() {
      return this.cases.filter((c) => !dayjs(c.inactive).isSameOrAfter(dayjs())).length;
    },
    ownCasesCount() {
      const owner = this.authService.state.userId;
      return this.cases.filter((c) => c.owner === owner).length;
    },
    archivedCasesCount() {
      if (this.activeFilters.includes('ARCHIVED')) {
        return this.cases.length;
      }
      let archivedCases = legalCaseService.state.cases.filter((c) => c.legalCaseStatus === 'ARCHIVED');
      if (this.activeFilters.includes('INACTIVE')) {
        archivedCases = archivedCases.filter((c) => !dayjs(c.inactive).isSameOrAfter(dayjs()));
      }
      if (this.activeFilters.includes('OWN')) {
        archivedCases = archivedCases.filter((c) => c.owner === this.authService.state.userId);
      }
      if (this.activeFilters.includes('NEW')) {
        archivedCases = archivedCases.filter((c) => this.hasNewSourceFiles(c));
      }
      return archivedCases.length;
    },
    isLoading() {
      return appService.isLoading();
    },
    readyCases() {
      return this.cases.filter((c) => c.ready);
    },
    notReadyCases() {
      return this.cases.filter((c) => !c.ready);
    },
    quickAccessCases() {
      const caseHistory = detailViewPersistenceService.allState;
      const cases = this.cases
        .filter((c) => c.legalCaseStatus !== 'ARCHIVED' && c.ready)
        .map((c) => {
          const recent = caseHistory[c.id];
          const timestamp =
            dayjs(c.lastSourceFileUploadDate) > dayjs(c.lastDuplicateSourceFileUploadDate)
              ? dayjs(c.lastSourceFileUploadDate)
              : dayjs(c.lastDuplicateSourceFileUploadDate);
          return {
            ...c,
            recentTimestamp: recent ? dayjs(recent.timestamp) : timestamp,
            recentAction: recent ? 'OPENED' : 'UPDATED',
          };
        })
        .filter((c) => c.recentTimestamp.isValid())
        .toSorted((a, b) => b.recentTimestamp.diff(a.recentTimestamp));

      return cases.slice(0, Math.min(this.$vuetify.display.md ? 3 : 4, cases.length));
    },
    casesFiltered() {
      return !!this.searchTerm?.trim() || this.activeFilters.length > 0;
    },
  },

  watch: {
    activeFilters: {
      handler(to: CaseListFilter[], from: CaseListFilter[]) {
        // save state in uri
        const toPreserved = to.filter((f) => preservedInUriFilters.includes(f));
        const fromPreserved = from.filter((f) => preservedInUriFilters.includes(f));
        const preservedChanged = toPreserved.length !== fromPreserved.length;
        if (preservedChanged) {
          this.$router.replace({ query: toPreserved.length ? { filters: to.join(',') } : {} });
        }
        // save OWN filter in prefs
        const newValue = to.includes(CASELIST_FILTERS.OWN);
        const oldValue = from.includes(CASELIST_FILTERS.OWN);
        if (oldValue !== newValue) {
          preferencesService.updatePreferences({ caseListPreferences: { listOwnCases: newValue } });
        }

        if (to.length) {
          this.$a.l(this.$a.e.CASELIST_FILTER, {
            activeFilters: this.activeFilters,
          });
        } else {
          this.$a.l(this.$a.e.CASELIST_FILTER_CLEAR);
        }
      },
      deep: true,
    },
    async $route() {
      this.applyUrlFilters();
    },
    searchTerm(to) {
      if (to) {
        this.$a.dl(this.$a.e.CASELIST_SEARCH, {
          searchTerm: this.searchTerm,
        });
      } else {
        this.$a.dl(this.$a.e.CASELIST_SEARCH_CLEAR);
      }
    },
  },

  mounted() {
    legalCaseService.subscribeToWebSocketEvents();
    // restore settings
    legalCaseSortService.setSorting(preferencesService.state.caseListPreferences.caseListSortMode as API.LegalCase.ListSorting);
    if (preferencesService.state.caseListPreferences.listOwnCases) {
      this.activeFilters.push(CASELIST_FILTERS.OWN);
    }
    this.applyUrlFilters();

    // fetch
    legalCaseService.load();

    // add listeners
    broadcastEventBus.subscribe('SHOW_OWN_INACTIVE_CASES_EVENT', this.handleShowOwnInactiveCasesEvent);
  },

  beforeUnmount() {
    broadcastEventBus.unsubscribe('SHOW_OWN_INACTIVE_CASES_EVENT', this.handleShowOwnInactiveCasesEvent);
  },

  methods: {
    reload() {
      window.location.reload();
    },
    applyUrlFilters() {
      const filtersFromUri = this.$route.query.filters ? (this.$route.query.filters as string).split(',').filter((f) => f in CASELIST_FILTERS) : [];
      for (const filter of filtersFromUri as CaseListFilter[]) {
        if (!this.activeFilters.includes(filter)) {
          this.activeFilters.push(filter);
        }
      }
    },
    formatToHumanDuration,
    showSheet(sourceFile: API.SourceFile.Response) {
      const message = `${[
        sourceFile.debugInfo?.status || '',
        sourceFile.debugInfo?.errorMessage || '',
        sourceFile.debugInfo?.batchJobUrl || '',
        sourceFile.debugInfo?.auditLogUrl || '',
        sourceFile.debugInfo?.awsLogUrl || '',
      ]
        .map((str) => str.replace(/^"+|"+$/g, ''))
        .join('\n')}\n`;
      const messageWithLinks = message.replace(/http(.*?)\n/g, (match, url) => `<a href="http${url}" target="_blank">http${url}</a>\n`);
      this.sheetContent = messageWithLinks.replace(/\n/g, '<br>');
      this.sheet = true;
    },
    showEditCaseDialog(inputCase: LegalCase) {
      this.$a.l(this.$a.e.CASELIST_EDIT);
      this.inputCase = { ...inputCase };
      this.showAddCaseDialog = true;
      this.addEditCaseDialogUseEditingMode = true;
    },
    showDemoInformation(inputCase: LegalCase) {
      this.inputCase = { ...inputCase };
      this.showDemoInformationDialog = true;
    },
    showFileManager(inputCase: LegalCase) {
      this.$a.l(this.$a.e.FILEMANAGER_OPEN);
      this.inputCase = { ...inputCase };
      this.showFileManagerDialog = true;
    },
    showDataLabel(inputCase: LegalCase) {
      this.$a.l(this.$a.e.CASELIST_DATALABELING);
      // do not make a copy of the inputCase, as we want to update the original
      this.inputCase = inputCase;
      this.showDataLabelDialog = true;
    },
    resetDialog() {
      this.showAddCaseDialog = false;
      this.addEditCaseDialogUseEditingMode = false;
    },
    refreshCases() {
      this.$a.l(this.$a.e.CASELIST_REFRESH);
      legalCaseService.refresh();
    },
    handleShowOwnInactiveCasesEvent() {
      this.activeFilters.push(CASELIST_FILTERS.INACTIVE);
    },
    hasNewSourceFiles(legalCase: LegalCase) {
      return (
        dayjs(legalCase.lastOwnerActivity) < dayjs(legalCase.lastSourceFileUploadDate) ||
        dayjs(legalCase.lastOwnerActivity) < dayjs(legalCase.lastDuplicateSourceFileUploadDate)
      );
    },
  },
});
</script>

<style scoped>
.content {
  overflow-y: scroll;
  /* NOTE(ndv): having 'scroll' instead of 'auto' prevents jumpiness */
  flex-grow: 1;
  background-color: rgb(var(--v-theme-background));
}

.case-search {
  margin: 0;
  padding: 0;
}

.no-cases-state-card {
  margin-top: 10%;
}

.filters :deep(.v-toolbar__content) {
  padding-left: 0;
  padding-right: 0;
}

.filters {
  background-color: transparent;
  margin-left: 10px;
}
</style>
