import type { Label } from '@/case-detail/subviews/labels/services/label.service';
import labelService from '@/case-detail/subviews/labels/services/label.service';

export function getMainLabel(labelIds: string[], labelsMap: Map<string, Label>) {
  if (labelIds.length === 0) return null;

  if (labelIds.length === 1) return labelsMap.get(labelIds[0]);

  const labels = labelIds.map((id) => labelsMap.get(id));
  const candidates = labels.filter((l) => l && !l.parentId && l.doctype && l.doctype !== 'type_other');
  if (candidates.length > 0) {
    return candidates[0];
  }

  return labelsMap.get(labelIds[0]);
}

// filtering labels themeselfs
type LabelData = {
  title: string;
  parentId?: string | null;
};

export const matches = (text: string, filter: string) => text.toLowerCase().includes(filter.toLowerCase());

export const labelOrParentMatchesFilter = (label: LabelData, filter: string) => {
  const labelMatches = matches(label.title, filter);
  if (labelMatches) {
    return true;
  }
  if (!label.parentId) {
    return false;
  }
  const parentLabel = labelService.getLabel(label.parentId);
  if (parentLabel) {
    return matches(parentLabel.title, filter);
  }
  return false;
};

export interface CustomDataTransferData {
  group: string;
  value: string;
  isTypeOther?: boolean;
}
