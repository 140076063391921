import { AbstractTenantEO } from '@/common/types/api-types/general.api.types';
import { ISODateTimeString, ObjectValues, UUID } from '@/common/types/common.types';

const LIST_SORTING = {
  TITLE_ASC: 'TITLE_ASC', // First name, Last last
  LAST_FIRST_NAME_ASC: 'LAST_FIRST_NAME_ASC',
  LAST_UPDATE_DESC: 'LAST_UPDATE_DESC',
  REF_ASC: 'REF_ASC',
} as const;
export type ListSorting = ObjectValues<typeof LIST_SORTING>;

// LegalCaseMetadataEO.java
export const PII_KEY = {
  INTERNAL_LABELS: 'INTERNAL_LABELS',
  INTERNAL_EMAIL_NOTIFICATION_SENT: 'INTERNAL_EMAIL_NOTIFICATION_SENT',
  INTERNAL_INTEGRATION: 'INTERNAL_INTEGRATION',
  PII_FIRSTNAME: 'PII_FIRSTNAME',
  PII_LASTNAME: 'PII_LASTNAME',
  PII_COMPANY: 'PII_COMPANY',
  PII_GENDER: 'PII_GENDER',
  PII_BIRTHDATE: 'PII_BIRTHDATE',
  PII_AHV_NR: 'PII_AHV_NR',
  PII_NATIONALITY: 'PII_NATIONALITY',
  PII_MARITAL_STATUS: 'PII_MARITAL_STATUS',
  PII_ADDRESS: 'PII_ADDRESS',
  PII_PLZ: 'PII_PLZ',
  PII_CITY: 'PII_CITY',
  PII_PHONE_NO: 'PII_PHONE_NO',
  PII_EMAIL: 'PII_EMAIL',
  PII_CHILDREN_IN_HOUSEHOLD: 'PII_CHILDREN_IN_HOUSEHOLD',
  PII_GENERAL_PRACTITIONER: 'PII_GENERAL_PRACTITIONER',
  JOB_TITLE: 'JOB_TITLE',
  JOB_ISCO_CODE: 'JOB_ISCO_CODE',
  JOB_POSITION: 'JOB_POSITION',
  JOB_COMPETENCE_LEVEL: 'JOB_COMPETENCE_LEVEL',
  JOB_PHYSICAL_LOAD: 'JOB_PHYSICAL_LOAD',
  JOB_TIME_PERCENTAGE: 'JOB_TIME_PERCENTAGE',
  JOB_INCOME_YEAR: 'JOB_INCOME_YEAR',
  JOB_INCOME_MONTHLY: 'JOB_INCOME_MONTHLY',
  JOB_INCOME_HOURLY: 'JOB_INCOME_HOURLY',
  JOB_START_DATE: 'JOB_START_DATE',
  JOB_END_DATE: 'JOB_END_DATE',
  JOB_FULL_TIME_HOURS: 'JOB_FULL_TIME_HOURS',
  JOB_EMPLOYER: 'JOB_EMPLOYER',
  JOB_EMPLOYER_ADDRESS: 'JOB_EMPLOYER_ADDRESS',
  JOB_ROLE: 'JOB_ROLE',
  JOB_ROLE_WORKPLACE: 'JOB_ROLE_WORKPLACE',
  JOB_TIME_COMMITMENT: 'JOB_TIME_COMMITMENT',
  JOB_TERMINATION: 'JOB_TERMINATION',
  JOB_CONTRACT_DURATION: 'JOB_CONTRACT_DURATION',
  INCIDENT_DATE: 'INCIDENT_DATE',
  INCIDENT_ICD10_CODE: 'INCIDENT_ICD10_CODE',
  INCIDENT_DESCRIPTION: 'INCIDENT_DESCRIPTION',
  INCIDENT_KIND: 'INCIDENT_KIND',
  INCIDENT_LAST_WORKING_DAY: 'INCIDENT_LAST_WORKING_DAY',
  INCIDENT_TYPE_REASON: 'INCIDENT_TYPE_REASON',
  INCIDENT_CONDITION_DESCRIPTION: 'INCIDENT_CONDITION_DESCRIPTION',
  INCIDENT_FIRST_ATTENDING_DOCTOR: 'INCIDENT_FIRST_ATTENDING_DOCTOR',
  INCIDENT_SUBSEQUENT_ATTENDING_DOCTOR: 'INCIDENT_SUBSEQUENT_ATTENDING_DOCTOR',
  INCIDENT_INSURANCE_COMPANY: 'INCIDENT_INSURANCE_COMPANY',
  INCIDENT_POLICE_NO: 'INCIDENT_POLICE_NO',
  CUSTOM_1: 'CUSTOM_1',
  CUSTOM_2: 'CUSTOM_2',
  CUSTOM_3: 'CUSTOM_3',
  // deprecated, demo
  INTERNAL_PERSON: 'INTERNAL_PERSON',
  INTERNAL_DAMAGE: 'INTERNAL_DAMAGE',
} as const;
export type PiiKey = ObjectValues<typeof PII_KEY>;

interface PiiData extends AbstractTenantEO {
  legalCaseId: UUID;
  key: PiiKey;
  value: string;
  extractor: 'EXTRACTION' | 'INTEGRATION' | 'MANUAL' | 'PIPELINE' | 'EMPTY';
  author: string;
  log: string;
}

// LegalCaseDiagnosisResponse.java
export interface Diagnosis {
  code: string;
  confidence: number;
  count: number;
  extractorId: string | null;
  id: UUID;
  legalCaseId: UUID;
  metadata: Record<string, number>;
  qualifications: Record<string, number>;
  relevant: boolean;
}

export type LegalCaseStatus = 'OPEN' | 'ARCHIVED';

export interface BaseLegalCaseResponse {
  created: ISODateTimeString;
  updated: ISODateTimeString;
  id: UUID;
  owner: UUID;
  displayLabel: string;
  reference: string;
  accessGroup: string | null;
  lastOwnerActivity: ISODateTimeString;
  legalCaseStatus: LegalCaseStatus;
  ready: boolean;
  inProgress: boolean;
  overDocumentCountLimit: boolean;
  sourceFileIds: UUID[];
  pii: Record<PiiKey, PiiData>;
  labeled: string[];
}

// This interface is used only for the list responces
// LegalCaseListResponse.java
export interface ListResponse extends BaseLegalCaseResponse {
  inactive: ISODateTimeString;
  lastActivity: ISODateTimeString; // optional, as mentioned "not used"
  legalCaseAccessUntil: ISODateTimeString;
  pipelineStatus: 'SOURCEFILE_REVIEW' | 'CASE_REVIEW' | 'ERROR' | null;
  sourceFileUploadFileNames: string[];
  lastSourceFileUploadDate: string;
  lastDuplicateSourceFileUploadDate: string;
  folders: string[] | null;
}
export interface Response extends BaseLegalCaseResponse {
  labels: UUID[];
  diagnoses: Diagnosis[];
}
// LegalCaseRequest.java
export interface CreateUpdateRequest {
  pii?: {
    [PII_KEY.PII_FIRSTNAME]: string;
    [PII_KEY.PII_LASTNAME]: string;
    [PII_KEY.PII_COMPANY]: string;
  };
  reference: string;
  owner: UUID;
  accessGroup: string;
  labeled: string[];
}

export interface CreateUpdateOwnerRequest {
  owner: UUID;
}
