import { Auth0Client, User as DefaultAuth0User } from '@auth0/auth0-spa-js';
import Hotjar from '@hotjar/browser';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { reactive } from 'vue';
import { RouteLocationNormalized } from 'vue-router';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t, i18nService } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import $a from '@/common/services/analytics/analytics';
import { Permission } from '@/common/services/auth/permissions';
import entityService, { TenantFeature, USER_ROLES, UserRole, UserRoleId } from '@/common/services/entity.service';
import logger from '@/common/services/logging';
import { openApiClientService } from '@/common/services/openapi.client.service';
import preferencesService from '@/common/services/preferences.service';
import userService from '@/common/services/users/user.service';
import { API } from '@/common/types/api.types';

// copied from https://github.com/auth0/auth0-spa-js/blob/4c0c755c51781ed8da7cd43b0f33da1c9166b963/src/constants.ts
const RECOVERABLE_ERRORS = [
  'login_required',
  'consent_required',
  'interaction_required',
  'account_selection_required',
  // Strictly speaking the user can't recover from `access_denied` - but they
  // can get more information about their access being denied by logging in
  // interactively.
  'missing_refresh_token',
  'access_denied',
];

// NOTE(dp): here we assume these fields always there
// if `name` and `email` actually could be undefined, logic should be adapted everywhere
export interface Auth0User extends DefaultAuth0User {
  name: string;
  email: string;
}

interface ServiceState {
  isLoggedIn: boolean;
  // everything below exists if isLoggedIn === true:
  userId: null | string; // from jwt
  user?: Auth0User;
  data: null | {
    role: UserRoleId; // from api via userId
    tenants: API.Auth.AuthorizedTenantsListItem[];
    userDetails: API.Auth.UserDetails;
    tenant: API.Auth.Tenant;
    permissions: Permission[];
  };
}

const initialState: ServiceState = {
  isLoggedIn: false,
  userId: null,
  user: undefined,
  data: null,
};

export class AuthService {
  state: ServiceState;

  // only null before init() called
  client: Auth0Client | null = null;

  USER_ROLES: USER_ROLES = entityService.USER_ROLES;

  constructor() {
    this.state = reactive(cloneDeep(initialState));

    // configure axios response interceptor if something goes wrong
    axios.interceptors.response.use((response) => response, this.responseErrorInterceptor.bind(this));

    // resolve roles titles after locale init
    i18nService.runAfterLocaleInit(() => {
      this.USER_ROLES = entityService.USER_ROLES;
    });
  }

  /**
   * Setup AuthService for a given tenant / user
   */
  async init(canonicalName: string | null = null) {
    if (this.client !== null) {
      return;
    }

    // for external users, we need to force disable SSO in notifications
    const urlParams = new URLSearchParams(window.location.search);
    const forceDisableSSO = urlParams.get('sso') === 'false' || localStorage.getItem('forceDisableSSO') === 'true';

    // NOTE: must be undefined, not null
    let organization;
    if (canonicalName !== null && !forceDisableSSO) {
      try {
        const authOrgResponse = await axios.get(config.API.AUTHORIZATION_ENDPOINT.ORG.replace('{canonicalName}', canonicalName));
        if (authOrgResponse.data) {
          logger.info('Got an auth0 org from BE');
          organization = authOrgResponse.data;
        }
      } catch (e) {
        appService.offline();
        handleError($t('Common.Auth.authError'), e, true);
        return;
      }
    }

    let redirectUri = `${window.location.origin}/callback`;
    if (urlParams.size) {
      redirectUri += `?${urlParams.toString()}`;
    }

    this.client = new Auth0Client({
      domain: config.AUTH.DOMAIN,
      clientId: config.AUTH.CLIENT_ID,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      authorizationParams: {
        audience: config.AUTH.AUDIENCE,
        redirect_uri: redirectUri,
        organization,
      },
    });

    // setup intercepter when auth is initalized
    axios.interceptors.request.use(this.jwtInterceptor.bind(this), this.errorInterceptor.bind(this));
    openApiClientService.init();
  }

  async jwtInterceptor(request: any) {
    if (request.url?.startsWith('/') || request.url?.startsWith(request.baseURL!)) {
      const token = await this.getToken();
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
      if (this.state.data?.tenant.id) {
        request.headers['X-Tenant-ID'] = this.state.data?.tenant.id;
      }
    }
    return request;
  }

  errorInterceptor(error: any) {
    if (!error?.response) {
      appService.offline();
    }
    return Promise.reject(error);
  }

  responseErrorInterceptor(error: any) {
    const errors = /[45]\d{2}/g;
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      appService.accessDenied(error?.response);
    } else if (error?.response?.status && error?.response?.status.toString().match(errors) && appService.state.outdated) {
      appService.fatal();
    }
    return Promise.reject(error);
  }

  getRoleTitleById(id: UserRoleId) {
    const roleConfig = Object.values(this.USER_ROLES).find((r) => r.id === id);
    return roleConfig?.title ?? id;
  }

  /**
   * Check if the current user has a certain role.
   */
  hasRole(requestedRole: UserRole) {
    if (!this.state.data) return false;
    return this.state.isLoggedIn && this.state.data.role === this.USER_ROLES[requestedRole]?.id;
  }

  isExternalRole(roleId: UserRoleId) {
    return roleId.startsWith('external:');
  }

  /**
   * Check if the current user has a certain permission.
   */
  hasPermission(requestedPermission: Permission) {
    if (!this.state.data) return false;
    return this.state.isLoggedIn && this.state.data.permissions.includes(requestedPermission);
  }

  areCasesSyncedViaAgent() {
    return this.hasFeature('ENABLE_AGENT');
  }

  /**
   * Check if the tenant has a certain feature
   */
  hasFeature(requestedFeature: TenantFeature) {
    if (!this.state.data) return false;
    if (!this.state.data.tenant.features) return false;
    return this.state.data.tenant.features.includes(requestedFeature);
  }

  /**
   * Check if the tenant has Dashboards enabled
   */
  hasDashboards() {
    return !!this.state?.data?.tenant.tenantConfig.copilotConfig.copilotDashboards.length;
  }

  /**
   * Fetch token silently or redirect the user to the login
   * @returns token if successful, null otherwise
   */
  async getToken() {
    if (this.client === null) {
      logger.error('initialize service before use');
      return null;
    }
    try {
      return await this.client.getTokenSilently();
    } catch (e: any) {
      if (RECOVERABLE_ERRORS.includes(e?.error)) {
        await this.client.loginWithRedirect({
          appState: { targetUrl: window.location },
        });
        return null;
      }
      appService.offline();
      handleError($t('Common.Auth.authError'), e);
      return null;
    }
  }

  /**
   * Defines if the role is available to switch to
   */
  showSwitchToRole(roleId: UserRoleId) {
    switch (roleId) {
      case 'legali:admin':
        return false;
      default:
        return true;
    }
  }

  /**
   * Temporarily switch authenticated user's role to requested one if valid.
   */
  async switchRole(to: UserRoleId) {
    if (!this.state.data) {
      logger.error('can not switch role before authorization');
      return;
    }

    this.state.data.tenants = [];
    this.state.data.role = to;
    await this.authorizeOnBackend(null, to);
  }

  /**
   * Logout and reset service state
   */
  async logout() {
    if (!this.client) {
      return Promise.resolve();
    }

    $a.l($a.e.APP_LOGOUT);

    try {
      await axios.post(config.API.AUTHORIZATION_ENDPOINT.LOGOUT);
    } catch (e) {
      logger.warn('Failed to register logout event, ignoring');
    }

    return this.client.logout();
  }

  /**
   * Verify that the user is authenticated by retriving a valid access token or redirecting them to the login.
   * @returns true if the user is authenticated, false otherwise.
   */
  async authenticate(tenantCanonicalName?: string) {
    if (this.state.isLoggedIn) {
      logger.info('User is already authenticated, returning');
      return true;
    }

    // NOTE(mba): auth0 currently does not support identifying orgs by their canonical name
    logger.info('Resolve tenants organization');
    await this.init(tenantCanonicalName ?? null);

    logger.info('User is not authenticated, retrieving token');
    const token = await this.getToken();
    logger.info(`Retrieved ${token === null ? 'invalid token' : 'valid token'}`);

    return token !== null;
  }

  /**
   * Verify authenticated user is authorized to access the requested tenant and initialize service state.
   * @returns true if the user is authorized, false otherwise.
   */
  async authorize(requestedTenantCanonicalName: string | null = null) {
    // tenant must be present in every route, otherwise bad route
    if (!requestedTenantCanonicalName) {
      return false;
    }

    if (this.client === null) {
      logger.error('initialize service before use');
      return false;
    }

    if (this.state.isLoggedIn) {
      logger.info('User is already authenticated, returning');
      return true;
    }

    const accessToken = await this.getToken();

    // setup permissions
    const success = await this.authorizeOnBackend(requestedTenantCanonicalName, null);
    if (!success || !accessToken) {
      return false;
    }
    // initialize service state
    const parsedAccessToken = this.parseJwt(accessToken);
    this.state.userId = parsedAccessToken['https://legal-i.ch/user_id'];
    const user = await this.client.getUser();
    if (!user) return false;
    this.state.user = user as Auth0User;

    await preferencesService.loadPreferences();
    const data = this.state.data!;

    // initialize analytics
    if (config.MIXPANEL.ENABLED) {
      const userProperties = {
        $name: this.state.user.name,
        $email: this.state.user.email,
        role: data.role,
        tenantId: data.tenant.id,
        tenantName: data.tenant.name,
        tenantCanonicalName: data.tenant.canonicalName,
        userDetailsProfession: data.userDetails.profession,
        userDetailsLocale: data.userDetails.locale,
        userDetailsBillingStatus: data.userDetails.billingStatus,
      };
      $a.setAuthUserProps(userProperties);
      logger.info('Initializing Mixpanel');
      logger.info(userProperties);
      // NOTE(mba): for analytics, we use email as user id
      mixpanel.identify(this.state.user.email);
      mixpanel.people.set(userProperties);
      $a.l($a.e.APP_LOGIN);
    }

    if (config.HOTJAR.ENABLED) {
      const userProperties = {
        userId: this.state.userId!,
        name: this.state.user.name,
        email: this.state.user.email,
        tenantId: data.tenant.id,
        tenantName: data.tenant.name,
        tenantCanonicalName: data.tenant.canonicalName,
        userDetailsProfession: data.userDetails.profession,
        userDetailsLocale: data.userDetails.locale,
        userDetailsBillingStatus: data.userDetails.billingStatus,
      };
      logger.info('Initializing Hotjar');
      logger.info(userProperties);
      // NOTE(mba): for analytics, we use email as user id
      Hotjar.identify(this.state.user.email!, userProperties);
    }

    if (config.INTERCOM.ENABLED) {
      const userName = userService.extractNames(this.state.user);
      const userProperties = {
        app_id: config.INTERCOM.APP_ID,
        name: userName?.fullName,
        email: this.state.user.email,
        user_id: this.state.userId,
        custom_launcher_selector: '.intercom-trigger',
      };
      logger.info('Initializing Intercom');
      logger.info(userProperties);
      // @ts-expect-error good
      window.Intercom('boot', userProperties);
    }
    this.state.isLoggedIn = true;

    // Check Lock Feature Flag
    if (!this.hasRole('LEGALI_ADMIN_ROLE') && this.hasFeature('ENABLE_MAINTENANCE_LOCK')) {
      appService.appError('MAINTENANCE_LOCK');
    }

    return true;
  }

  // ### PRIVATE

  /**
   * Retrieve user authorization (role, permissions, authorized tenants) for the requested tenant and update service state.
   * @returns true if everything works, false in case of an error
   */
  async authorizeOnBackend(tenantCanonicalName: string | null = null, switchToRole: UserRoleId | null = null) {
    try {
      let url;
      if (switchToRole) {
        url = `${config.API.AUTHORIZATION_ENDPOINT.SWITCH_ROLE}/${switchToRole}`;
      } else if (tenantCanonicalName) {
        url = `${config.API.AUTHORIZATION_ENDPOINT.BASE}/${tenantCanonicalName}`;
      } else {
        url = `${config.API.AUTHORIZATION_ENDPOINT.BASE}`;
      }
      const response = await axios.get(url);
      this.state.data = {
        permissions: response.data.permissions,
        role: response.data.role,
        tenant: response.data.tenant,
        tenants: [...response.data.authorizedTenants].sort((a, b) => a.name.localeCompare(b.name)),
        userDetails: response.data.userDetails,
      };
      const { tenant } = this.state.data!;
      appService.setBranding(tenant.logoUrl, tenant.tenantConfig.brandingConfig.primaryColor, tenant.tenantConfig.brandingConfig.secondaryColor);
      return true;
    } catch (e) {
      appService.offline();
      handleError($t('Common.Auth.authError'), e);
      return false;
    }
  }

  /**
   * Retrieve the canonical name of the default tenant from the authorized tenants in the access token
   * @returns the canonical name of the default tenant
   */
  async getDefaultTenantCanonicalName() {
    logger.info('Getting default tenant canonical name from jwt');
    const isAuthenticated = await this.authenticate();
    if (!isAuthenticated) {
      // ignore, user will be redirected
      return '';
    }

    try {
      await this.authorizeOnBackend();

      await preferencesService.loadPreferences();
      const tenantCanonicalNameFromPreferences = preferencesService.state.workspacePreferences.preferredTenant;
      if (tenantCanonicalNameFromPreferences) {
        logger.info('Getting default tenant canonical name from preferences');
        return tenantCanonicalNameFromPreferences;
      }

      // if auth0 preferred tenant is null
      return this.state.data!.tenant.canonicalName;
    } catch (e) {
      handleError($t('Common.Auth.authError'), e);
      return '';
    }
  }

  /**
   * Parse jwt token into an object
   * @returns json-parsed, object representation of the access token
   */
  parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );
    return JSON.parse(jsonPayload);
  }

  /**
   * Verify if the current state is a result of a callback from auth0
   * @returns true if the state is a callback from auth0, false otherwise
   */
  isCallbackFromAuth0() {
    return window.location.search.includes('code=') && window.location.search.includes('state=');
  }

  /**
   * Handle callback from auth0
   * @returns string pathname to redirect to if successful, null otherwise
   */
  async handleCallback() {
    if (!this.isCallbackFromAuth0()) {
      return null;
    }

    try {
      await this.init();
      const { appState } = await this.client!.handleRedirectCallback();
      return appState.targetUrl.pathname + appState.targetUrl.search;
    } catch (e) {
      handleError($t('Common.Auth.authError'), e);
      return null;
    }
  }
}

export const authService = new AuthService();

export function authGuardFactory(requiredViewPermission: Permission) {
  return async (to: RouteLocationNormalized) => {
    logger.info('Entered AuthGuard');
    logger.info('Authentication...');
    const tenantCanonicalName = to.params?.tenant as string | undefined;
    const isAuthenticated = await authService.authenticate(tenantCanonicalName);
    logger.info(`User isAuthenticated: ${isAuthenticated}`);
    // login redirect started, do not call next
    if (!isAuthenticated) {
      logger.warn('User is not authenticated, redirecting to login');
      return false;
    }

    logger.info('Authorization...');
    const isAuthorized = await authService.authorize(tenantCanonicalName);
    if (!isAuthorized) {
      logger.warn('User is unauthorized');
      appService.accessDenied();
      return false;
    }

    logger.info('Checking access permissions for the requested view...');
    if (!authService.hasPermission(requiredViewPermission)) {
      logger.warn('User cannot access requested view');
      appService.accessDenied();
      return false;
    }

    // store last used tenant
    if (preferencesService.state.workspacePreferences.preferredTenant !== tenantCanonicalName) {
      logger.info("Storing user's preferred tenant");
      await preferencesService.updatePreferences({ workspacePreferences: { preferredTenant: tenantCanonicalName ?? '' } });
    }

    logger.info('All good, leaving AuthGuard');
    return true;
  };
}

// NOTE(mba): this is only used for the add tenant screen, maybe other /a/ views
export function legaliAdminAuthGuardFactory() {
  return async () => {
    logger.info('Entered LegaliAdminAuthGuard');
    const isAuthenticated = await authService.authenticate();
    if (!isAuthenticated) {
      logger.warn('User is not authenticated, redirecting to login');
      return false;
    }
    const token = await authService.getToken();
    if (!token) return false;

    const parsedAccessToken = authService.parseJwt(token);
    const isLegaliAdmin = parsedAccessToken['https://legal-i.ch/legali_admin'];
    if (!isLegaliAdmin) {
      logger.warn('User is not a legal-i admin');
      appService.accessDenied();
      return false;
    }

    return true;
  };
}
