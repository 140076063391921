import ColorHash from 'color-hash';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { $t } from '@/app/i18n/i18n.service';
import { Locale } from '@/app/i18n/locale';
import appService from '@/app/services/app.service';
import { TenantAuthorizationRequest, User, UserCreateResponse, UserDTO } from '@/common/generated-types/openapi';
import { authService } from '@/common/services/auth/auth.service';
import { UserProfession } from '@/common/services/entity.service';
import { $api } from '@/common/services/openapi.client.service';
import { UUID } from '@/common/types/common.types';

const AGENT_CLIENT_ID_LENGTH = 32;
type SpecialUserId = 'system' | 'pipeline' | 'support' | 'anonymous' | 'guest' | 'unknown';

interface UserAvatarData {
  type: 'initials' | 'icon';
  content: string;
  color: string;
}

interface ServiceState {
  users: User[];
  supportUserIds: string[];
}

export class UserService {
  state: ServiceState;

  colorHash: ColorHash;

  constructor() {
    this.state = reactive({
      users: [],
      supportUserIds: [],
    });

    this.colorHash = new ColorHash({ lightness: 0.4 });
  }

  async init() {
    if (this.state.users.length > 0) {
      return Promise.resolve();
    }
    return Promise.all([this.loadSupportUsers(), this.loadUsers()]);
  }

  /** * CRUD ** */
  async loadUsers() {
    const client = await $api('userClient');
    try {
      const response = await client.listUsers();
      this.state.users = response.data;
    } catch (error) {
      handleError($t('Common.User.loadError'), error);
    }
  }

  async loadSupportUsers() {
    // load admin users which are displayed as support users
    const client = await $api('userClient');
    try {
      const response = await client.listAdminUsers();
      this.state.supportUserIds = response.data;
    } catch (error) {
      handleError($t('Common.User.loadError'), error);
    }
  }

  async createUser(createUserRequest: UserDTO) {
    const client = await $api('userClient');
    try {
      const response = await client.createUser(null, createUserRequest);
      return response?.data as UserCreateResponse;
    } catch (e) {
      handleError($t('Common.User.createError'), e);
      return null;
    }
  }

  async updateUser(userId: string, updatedUserData: Partial<UserDTO>): Promise<void> {
    const updateUserRequest = await this.createUserDTO(userId, updatedUserData);
    this.syncUI(userId, updatedUserData);
    const client = await $api('userClient');
    try {
      // find object in state and update it
      const response = await client.updateUser(userId, updateUserRequest);
      const updatedUser = response.data;
      const index = this.state.users.findIndex((user) => user.user_id === userId);
      if (index !== -1) {
        this.state.users[index] = updatedUser;
      }
    } catch (error) {
      handleError($t('Common.User.updateError'), error);
    }
  }

  private syncUI(userId: string, updatedUserData: Partial<UserDTO>) {
    const currentUser = this.getUser(userId);
    const tenant = authService.state.data?.tenant;
    if (!currentUser || !tenant) {
      return;
    }
    if (updatedUserData && currentUser.userDetails && currentUser.userDetails.professions[tenant.id] !== updatedUserData.profession) {
      if (updatedUserData.profession) {
        currentUser.userDetails.professions[tenant.id] = updatedUserData.profession;
      }
    }
    if (updatedUserData && currentUser.userDetails && currentUser.userDetails?.locale !== updatedUserData.locale) {
      if (updatedUserData.locale) {
        currentUser.userDetails.locale = updatedUserData.locale;
      }
    }
    if (updatedUserData && currentUser.userDetails && currentUser.userDetails?.billing_status !== updatedUserData.billingStatus) {
      currentUser.userDetails.billing_status = updatedUserData.billingStatus as 'BILLED' | 'NON_BILLED';
    }
    const index = this.state.users.findIndex((user) => user.user_id === userId);
    if (index !== -1) {
      this.state.users[index] = currentUser as User;
    }
  }

  async createUserDTO(userId: string, fieldsToUpdate: Partial<UserDTO>) {
    const user = this.state.users.find((f) => f.user_id === userId)!;
    const userDTO = {} as UserDTO;
    userDTO.email = user.email;
    userDTO.name = user.name;
    userDTO.role = user.role;
    userDTO.locale = user.userDetails?.locale;

    if (authService.state.data) {
      const currentTenantId = authService.state.data.tenant.id;
      userDTO.profession = user.userDetails?.professions[currentTenantId];
    }
    userDTO.billingStatus = user.userDetails?.billing_status as 'BILLED' | 'NON_BILLED';

    Object.assign(userDTO, fieldsToUpdate);
    return userDTO;
  }

  async updateAuthorizedWorkspace(userId: string, authorizedTenant: TenantAuthorizationRequest) {
    const client = await $api('userClient');
    const updatedUser = this.getUser(userId);
    if (!updatedUser) {
      return Promise.reject();
    }
    updatedUser.role = authorizedTenant.role;
    const index = this.state.users.findIndex((user) => user.user_id === userId);
    if (index !== -1) {
      this.state.users[index] = updatedUser as User;
    }
    try {
      await client.authorizeUser(userId, authorizedTenant);
      await this.loadUsers();
    } catch (e) {
      handleError($t('Common.User.updateError'), e);
    }
    return Promise.resolve();
  }

  async updateUserLocale(userId: string, locale: Locale) {
    const user = this.getUser(userId);
    try {
      if (!user) {
        throw new Error('User not found');
      }

      const client = await $api('userClient');

      // NOTE(ae): hack for admins to change the support user locale
      if (user.name === 'legal-i Support') {
        await client.updateUserLocale(userId, {
          name: 'support',
          email: 'support@legal-i',
          role: 'legali:admin',
          profession: 'OTHER',
          billingStatus: 'NON_BILLED',
          locale: locale.toString(),
          accessGroups: [],
        });
        return;
      }

      const updateLocaleRequest = await this.createUserDTO(userId, { locale: locale.toString() });
      await client.updateUserLocale(userId, updateLocaleRequest);
    } catch (e) {
      handleError($t('Common.changesSaveError'), e);
    }
  }

  async updateUserProfession(userId: string, profession: UserProfession) {
    const user = this.getUser(userId);
    try {
      if (!user) {
        throw new Error('User not found');
      }
      const updateProfessionRequest = await this.createUserDTO(userId, { profession });
      const client = await $api('userClient');
      await client.updateUserProfession(userId, updateProfessionRequest);
      appService.info($t('Copilot.professionUpdateSuccessMessage'));
    } catch (e) {
      handleError($t('Common.changesSaveError'), e);
    }
  }

  async deleteUser(userId: string) {
    const client = await $api('userClient');
    try {
      const response = await client.deleteUser(userId);
      this.state.users = response.data;
    } catch (error) {
      handleError($t('Common.User.deleteError'), error);
    }
  }

  async unauthorizeUser(userId: string, tenantId: string) {
    const client = await $api('userClient');
    try {
      const response = await client.unauthorizeUser({ id: userId, tenantId });
      this.state.users = response.data;
    } catch (error) {
      handleError($t('Common.User.unauthorizeError'), error);
    }
  }

  async resetMFA(userId: string) {
    const client = await $api('userClient');
    try {
      await client.resetMFA(userId);
    } catch (error) {
      handleError($t('Common.User.MFAResetError'), error);
    }
  }

  async evictCache() {
    const client = await $api('userClient');
    try {
      await client.evictCacheUserList();
    } catch (error) {
      handleError($t('Common.User.loadError'), error);
    }
  }

  /** * HELPERS ** */
  getUser(userId: string | SpecialUserId | undefined | null): User | Partial<User> | null {
    if (!userId) {
      return null;
    }

    // 1 - worksapce user from user directory
    const isInDirectory = this.state.users.find((u) => u.user_id === userId);
    if (isInDirectory) {
      return isInDirectory as User | Partial<User>;
    }

    // 2 - legal-i support
    if (this.isSupport(userId) || userId === 'support') {
      return {
        user_id: userId,
        name: 'legal-i Support',
        role: 'legali:admin',
      };
    }

    // 3 - special cases
    // NOTE(dp): no proof of usage found, remove?
    if (userId === 'system') {
      return {
        user_id: userId,
        name: 'System',
      };
    }

    // NOTE(dp): no proof of usage found, remove?
    if (userId === 'anonymous') {
      return {
        user_id: userId,
        name: 'Anonymous',
      };
    }

    if (userId === 'pipeline') {
      return {
        user_id: userId,
        name: 'Pipeline',
      };
    }

    // NOTE(dp): no proof of usage found, remove?
    if (userId === 'guest') {
      return {
        user_id: userId,
        name: 'Guest',
      };
    }

    // agent
    // example id: wHYknhCM17BG3xaRtbrekBWFQbSifeLU
    if (userId.length === AGENT_CLIENT_ID_LENGTH) {
      return {
        user_id: userId,
        name: 'Agent',
      };
    }

    return {
      user_id: 'unknown',
      name: $t('Common.User.unknownUser') + (authService.hasPermission('LEGALI_ADMIN') && !!userId ? ` (${userId})` : ''),
    };
  }

  getAvatar(userId: string | SpecialUserId): UserAvatarData {
    const isInDirectory = this.state.users.find((u) => u.user_id === userId);
    if (isInDirectory) {
      return {
        type: 'initials',
        content: this.extractInitials(this.extractNames(isInDirectory)?.fullName ?? isInDirectory.name),
        color: this.getAvatarColor(userId),
      } as UserAvatarData;
    }

    if (this.isSupport(userId) || userId === 'support') {
      return {
        type: 'initials',
        content: 'LS',
        color: this.getAvatarColor(userId),
      } as UserAvatarData;
    }

    if (userId === 'system') {
      return {
        type: 'icon',
        content: 'mdi-chip',
        color: this.getAvatarColor(userId),
      } as UserAvatarData;
    }

    if (userId === 'anonymous') {
      return {
        type: 'icon',
        content: 'mdi-incognito',
        color: this.getAvatarColor(userId),
      } as UserAvatarData;
    }

    if (userId === 'pipeline') {
      return { type: 'icon', content: 'mdi-pipe', color: this.getAvatarColor(userId) } as UserAvatarData;
    }

    if (userId === 'guest') {
      return {
        type: 'icon',
        content: 'mdi-account-circle-outline',
        color: this.getAvatarColor(userId),
      } as UserAvatarData;
    }

    if (userId.length === AGENT_CLIENT_ID_LENGTH) {
      return {
        type: 'icon',
        content: 'mdi-hat-fedora',
        color: this.getAvatarColor(userId),
      } as UserAvatarData;
    }

    return {
      type: 'icon',
      content: 'mdi-account-circle-outline',
      color: this.getAvatarColor(userId),
    } as UserAvatarData;
  }

  extractNames(user: User | Partial<User>): { firstName: string; lastName: string; fullName: string } | null {
    let { name } = user;
    const { email } = user;

    if (!name || !email) {
      return null;
    }

    const emailDomainName = email.split('@')[1];

    if (user.sso && (emailDomainName.includes('baloise') || emailDomainName.includes('mobi'))) {
      name = name?.replace(/,/g, '') ?? '';

      const fullNameParts = name.split(' ');
      fullNameParts.reverse();

      const firstName = fullNameParts[0];
      const lastName = fullNameParts[1];

      const fullName = `${firstName} ${lastName}`.trim();

      return {
        firstName,
        lastName,
        fullName,
      };
    }

    const nameParts = name.split(' ');
    return {
      firstName: nameParts[0],
      lastName: nameParts[1] ?? '',
      fullName: name,
    };
  }

  getFullName(userId?: string | SpecialUserId | null, manualFallbackForUnknownUser?: string): string {
    if (!userId) {
      return manualFallbackForUnknownUser ?? $t('Common.User.unknownUser');
    }

    const user = this.getUser(userId)!;
    // NOTE(ae): hack for mobi and baloise SSO users, See Story https://legal-i.atlassian.net/browse/LEG-7066
    const fullUserName = this.extractNames(user)?.fullName ?? user.name;
    return (user.user_id && user.user_id !== 'unknown') || !manualFallbackForUnknownUser ? fullUserName ?? '' : manualFallbackForUnknownUser;
  }

  getOrganisationUserId(homeTenantId: UUID) {
    return this.state.users.find((user) => user.homeTenantId === homeTenantId && user.role === 'external:organisation')?.user_id;
  }

  getUsers() {
    return this.state.users;
  }

  getSupports() {
    return this.state.supportUserIds;
  }

  getMyself(): User | Partial<User> {
    return this.getUser(authService.state.userId!)!;
  }

  getAvatarColor(userId: string | SpecialUserId) {
    if (!userId) {
      return 'primary';
    }
    // NOTE(mba): we need to add this magic token, otherwise Kusi's color looks like shit, literally.
    return this.colorHash.hex(`${userId} 62h`);
  }

  extractInitials(name: string) {
    const words = name.match(/(?<=\s|^)[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]+/g) || [];

    if (words.length === 0) {
      return '';
    }

    if (words.length === 1) {
      return words[0][0].toUpperCase();
    }

    const firstInitial = words[0]![0].toUpperCase();
    const lastInitial = words[words.length - 1][0].toUpperCase();

    return `${firstInitial}${lastInitial}`;
  }

  isSupport(userId?: string | null) {
    return userId && this.getSupports().some((adminId) => adminId === userId);
  }
}

export default new UserService();
export const UserServiceClass = UserService;
